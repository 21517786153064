import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ScreenOne from './screens/screenOne';
import ScreenTwo from './screens/screenTwo';
import ScreenThree from './screens/screenThree';
import ScreenFour from './screens/screenFour';
import ScreenFive from './screens/screenFive';
import ScreenSix from './screens/screenSix';
import i18n from './../../i18n';
import { useSelector, useDispatch } from 'react-redux';
import { naturaAlertApi } from './../../api/index';
import swal from '@sweetalert/with-react';
import annualAssessment from '../../redux/actions/annualAssessment';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    textAlign: 'center',
    marginTop: '20px',
  },
}));

function getSteps() {
  return [
    i18n.t('Screen2Title'),
    i18n.t('Screen3Title'),
    i18n.t('Screen4Title'),
    i18n.t('Screen5Title'),
    i18n.t('Screen6Title'),
    i18n.t('Screen7Title'),
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <ScreenOne />;
    case 1:
      return <ScreenTwo />;
    case 2:
      return <ScreenThree />;
    case 3:
      return <ScreenFour />;
    case 4:
      return <ScreenFive />;
    case 5:
      return <ScreenSix />;
    default:
      return 'Unknown stepIndex';
  }
}

export default function AnnualAssessmentWizard({ handleClose }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  var dispatch = useDispatch();
  var id = useSelector((state) => state?.annualAssessment?.form?.id);
  var name = useSelector((state) => state?.annualAssessment?.form?.name);
  var email = useSelector((state) => state?.annualAssessment?.form?.email);
  var iba = useSelector((state) => state?.annualAssessment?.form?.iba);
  var year = useSelector((state) => state?.annualAssessment?.form?.year) || 0;
  var threats = useSelector((state) =>
    state?.annualAssessment?.form?.threats.map((o) => ({ ...o, tableData: {} })),
  );
  var ibaSpeciesList = useSelector((state) =>
    state?.annualAssessment?.form?.ibaSpeciesList.map((o) => ({ ...o, tableData: {} })),
  );
  var habitatList = useSelector((state) =>
    state?.annualAssessment?.form?.habitatList.map((o) => ({ ...o, tableData: {} })),
  );
  const siteLegalProtection = useSelector((state) => state.annualAssessment.form?.siteLegalProtection);
  const managmentPlanInfo = useSelector((state) => state.annualAssessment.form?.managmentPlanInfo);
  const conservationActivity = useSelector((state) => state.annualAssessment.form?.conservationActivity);
  const protectionOrganizationAnswer = useSelector(
    (state) => state.annualAssessment.form?.protectionOrganizationAnswer,
  );
  const relationshipBetweenSeo = useSelector((state) => state.annualAssessment.form?.relationshipBetweenSeo);
  const activitiesDoneByOrg = useSelector((state) => state.annualAssessment.form?.activitiesDoneByOrg);
  const qualityOfAssessment = useSelector((state) => state.annualAssessment.form?.qualityOfAssessment);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFinish = async () => {
    const payLoad = {
      activitiesDoneByOrg: activitiesDoneByOrg,
      qualityOfAssessment: qualityOfAssessment,
      careTakerName: name,
      conservationActivity: conservationActivity,
      email: email,
      habitatDataList: habitatList,
      ibaCode: iba.code,
      legallyProtectedAreaInfo: siteLegalProtection,
      managmentPlanInfo: managmentPlanInfo,
      pressureThreatList: threats,
      protectionOrganizationAnswer: protectionOrganizationAnswer,
      relationshipBetweenSeo: relationshipBetweenSeo,
      stateJudgementDataList: ibaSpeciesList,
      yearOfAssessment: year.code,
    };
    if (id) {
      try {
        await naturaAlertApi
          .putAnnualAssessment(payLoad, id)
          .then((_res) => {
            swal({
              title: 'Assessment Updated!',
              icon: 'success',
            });
            handleClose();
            dispatch(annualAssessment.reset());
          })
          .catch((res) => {
            swal({
              title: 'Failed to Updated!',
              icon: 'error',
            });
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await naturaAlertApi
          .postAnnualAssessment(payLoad)
          .then((_res) => {
            swal({
              title: 'Assessment created!',
              icon: 'success',
            });
            handleClose();
            dispatch(annualAssessment.reset());
          })
          .catch((res) => {
            swal({
              title: 'Failed to create!',
              icon: 'error',
            });
          });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <div>{getStepContent(activeStep)}</div>
            <div className={classes.buttons}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" color="primary" onClick={handleFinish}>
                  Finish
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
