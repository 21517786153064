import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { naturaAlertApi } from './../../api/index';
import { useSelector, useDispatch } from 'react-redux';
import swal from '@sweetalert/with-react';
import i18n from './../../i18n';
import careTakers from '../../redux/actions/careTakers';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: theme.spacing(2),
  },
}));
export default function CareTakerList() {
  const classes = useStyles();
  const selectedRow = useSelector((state) => state?.careTakers?.selectedIba);
  const userList = useSelector((state) => state?.careTakers?.userList.map((o) => ({ ...o, tableData: {} })));
  const [isLoading, setIsLoading] = useState(false);
  const selectedIba = useSelector((state) => state?.careTakers?.selectedIba);
  const dispatch = useDispatch();
  var title = 'IBA Caretakers';
  if (selectedRow) {
    title = 'IBA Caretakers for ' + selectedRow?.internationalName;
  }

  useEffect(() => {
    if (selectedRow) {
      setIsLoading(true);
      naturaAlertApi
        .getCareTakersForIBA({ ibaid: selectedRow.id })
        .then((data) => {
          setIsLoading(false);
          dispatch(careTakers.setUserList(data.userViewModels));
        })
        .catch((_error) => {
          setIsLoading(false);
          dispatch(careTakers.setUserList([]));
        });
    }
  }, [dispatch, selectedRow]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <MaterialTable
          columns={[{ title: 'Email', field: 'email' }]}
          options={{
            search: false,
          }}
          data={userList}
          title={title}
          actions={[
            {
              icon: 'delete',
              tooltip: 'Delete Users',
              onClick: (_event, rowData) => {
                swal({
                  title: i18n.t('AreYouSure'),
                  icon: 'warning',
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    var payload = {
                      ibaId: selectedIba.id,
                      userIds: [],
                    };
                    payload.userIds.push(rowData.id);
                    naturaAlertApi.removeCareTakers(payload).then((_res) => {
                      swal({
                        text: 'Removed',
                        icon: 'success',
                      });
                      var cT = userList.filter((t) => t.id !== rowData.id);
                      dispatch(careTakers.setUserList(cT));
                    });
                  }
                });
              },
            },
          ]}
        />
      )}
    </div>
  );
}
