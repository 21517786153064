import { createAction } from '@reduxjs/toolkit';

const setThreats = createAction('assessment/setThreats');
const setIba = createAction('assessment/setIba');
const setName = createAction('assessment/setName');
const setEmail = createAction('assessment/setEmail');
const setError = createAction('assessment/setError');
const setYear = createAction('assessment/setYear');
const setIbaSpeciesList = createAction('assessment/setIbaSpeciesList');
const addSpeciesList = createAction('assessment/setAddSpeciesList');
const updateSpeciesList = createAction('assessment/setUpdateSpeciesList');
const removeSpeciesList = createAction('assessment/setRemoveSpeciesList');

const setHabitatList = createAction('assessment/setHabitatList');
const addHabitatList = createAction('assessment/addHabitatList');
const updateHabitatList = createAction('assessment/updateHabitatList');
const removeHabitatList = createAction('assessment/removeHabitatList');

const setSiteLegalProtection = createAction('assessment/setSiteLegalProtection');
const setManagmentPlanInfo = createAction('assessment/setManagmentPlanInfo');
const setConservationActivity = createAction('assessment/setConservationActivity');

const setProtectionOrganizationAnswer = createAction('assessment/setProtectionOrganizationAnswer');
const setRelationshipBetweenSeo = createAction('assessment/setRelationshipBetweenSeo');
const setActivitiesDoneByOrg = createAction('assessment/setActivitiesDoneByOrg');
const setQualityOfAssessment = createAction('assessment/setQualityOfAssessment');

const reset = createAction('assessment/reset');
const setFrom = createAction('assessment/setFrom');

export default {
  setThreats,
  setIba,
  setName,
  setEmail,
  setError,
  setYear,
  setIbaSpeciesList,
  addSpeciesList,
  updateSpeciesList,
  removeSpeciesList,
  setHabitatList,
  addHabitatList,
  updateHabitatList,
  removeHabitatList,
  setSiteLegalProtection,
  setManagmentPlanInfo,
  setConservationActivity,
  setProtectionOrganizationAnswer,
  setRelationshipBetweenSeo,
  setActivitiesDoneByOrg,
  setQualityOfAssessment,
  reset,
  setFrom,
};
