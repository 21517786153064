const { createSlice } = require('@reduxjs/toolkit');

const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    isOpen: false,
    message: '',
    severity: 'success',
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    showSnack: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
  },
});

export default snackBarSlice;
