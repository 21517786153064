import { createAction } from '@reduxjs/toolkit';

const fetchAppConfigAsync = createAction('app/fetchAppConfigAsync');

const setAppConfig = createAction('app/setAppConfig');

const setLanguageAsync = createAction('app/setLanguageAsync');

const logoutAsync = createAction('app/logoutAsync');

export default {
  fetchAppConfigAsync,
  setAppConfig,
  setLanguageAsync,
  logoutAsync,
};
