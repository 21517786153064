import React, { useState } from 'react';
import './IBAList.scss';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import careTakers from '../../redux/actions/careTakers';
import { naturaAlertApi } from '../../api';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function IBAList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);

  const updateSelectedRow = (row) => {
    dispatch(careTakers.setSelectedIBA(row));
    setSelectedRow(row);
  };

  return (
    <div className="iba-container">
      <div className={classes.root}>
        <MaterialTable
          columns={[
            {
              width: 150,
              title: 'Code',
              field: 'code',
            },
            { title: 'Country', field: 'countryName' },
            { title: 'International Name', field: 'internationalName' },
            { title: 'National Name', field: 'nationalName' },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              naturaAlertApi
                .getPagedIbas({
                  sorting: 'code',
                  skipCount: query.page * query.pageSize,
                  maxResultCount: query.pageSize,
                  search: query.search,
                })
                .then((res) => {
                  resolve({
                    data: res.items,
                    page: query.page,
                    totalCount: res.totalCount,
                  });
                });
            })
          }
          onRowClick={(_, row) => updateSelectedRow(row)}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            pageSizeOptions: [5, 10, 15, 20],
            rowStyle: (rowData) => ({
              backgroundColor: selectedRow?.tableData?.id === rowData.tableData.id ? '#41877f' : '#FFF',
            }),
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Add Users',
              onClick: (_event, rowData) => {
                setSelectedRow(rowData);
                dispatch(careTakers.setSelectedIBA(rowData));
                dispatch(careTakers.setIsUsersDialogOpen(true));
              },
            },
          ]}
          title="IBA"
        />
      </div>
    </div>
  );
}
