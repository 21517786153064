//temp list of years.. //TODO should fetch from backend.
export const YEARS_LIST = Array.from(new Array(50), (v, i) => new Date().getFullYear() + i).map((item) => {
  return { code: item };
});

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const ERROR = 'ERROR';
export const CLEAR = 'CLEAR';

export const ASSESSMENT_YEARS_LIST = Array.from(
  new Array(10),
  (v, i) => new Date().getFullYear() - 2 + i,
).map((item) => {
  return { code: item };
});

export const FEED_BACK_TOPICS = [
  {
    value: 'BugReport',
    id: 1,
  },
  {
    value: 'SuggestionsForImprovement',
    id: 2,
  },
  {
    value: 'GeneralComments',
    id: 3,
  },
];

//need to replace with actual data
export const QUALITY_OF_ASSESSMENT = [
  {
    value: 'Good',
    id: 0,
  },
  {
    value: 'Medium',
    id: 1,
  },
  {
    value: 'Poor',
    id: 2,
  },
];

//need to replace with actual data
export const REMAINING = [
  {
    value: '10%',
    id: 1,
  },
  {
    value: '30%',
    id: 2,
  },
  {
    value: '50%',
    id: 3,
  },
  {
    value: '70%',
    id: 4,
  },
  {
    value: '90%',
    id: 5,
  },
];

export const COUNTRIES = [
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
];

export const IMPACT = [
  {
    value: 0,
    label: 'Low',
  },
  {
    value: 1,
    label: 'Medium',
  },
  {
    value: 2,
    label: 'High',
  },
  {
    value: 3,
    label: 'VeryHigh',
  },
];

export const SCOPE = [
  {
    value: 0,
    label: 'Negligible',
  },
  {
    value: 1,
    label: 'ReducedArea',
  },
  {
    value: 2,
    label: 'GreaterPartOfArea',
  },
  {
    value: 3,
    label: 'AllSpace',
  },
];

export const SEVERITY = [
  {
    value: 0,
    label: 'Insignificant',
  },
  {
    value: 1,
    label: 'Slow',
  },
  {
    value: 2,
    label: 'Moderate',
  },
  {
    value: 3,
    label: 'Quick',
  },
];

export const TIMING = [
  {
    value: 0,
    label: 'InThePast',
  },
  {
    value: 1,
    label: 'LongTermInTheFuture',
  },
  {
    value: 2,
    label: 'ShortTermInTheFuture',
  },
  {
    value: 3,
    label: 'Nowadays',
  },
];

export const THREAT_TYPE = [
  {
    value: '1',
    label: 'ResidentialAndCommercialDevelopment',
  },
  {
    value: '2',
    label: 'AgriculturalExpansionAndIntensification',
  },
  {
    value: '3',
    label: 'EnergyProductionAndMining',
  },
  {
    value: '4',
    label: 'TransportationAndServiceCorridors',
  },
  {
    value: '5',
    label: 'BiologicalResourceUse',
  },
  {
    value: '6',
    label: 'HumanIntrusionAndDisturbance',
  },
  {
    value: '7',
    label: 'NaturalSystemModification',
  },
  {
    value: '8',
    label: 'InvasiveAndOtherProblematicSpeciesGenesDiseases',
  },
  {
    value: '9',
    label: 'Pollution',
  },
  {
    value: '10',
    label: 'GeologicalEvents',
  },
  {
    value: '11',
    label: 'ClimateChangeAndSevereWeather',
  },
  {
    value: '12',
    label: 'OtherOptions',
  },
];

export const THREAT_SUBTYPE = [
  {
    value: '1.1',
    label: 'HousingAndUrbanAreas',
    threatType: '1',
  },
  {
    value: '1.2',
    label: 'CommercialAndIndustrialAreas',
    threatType: '1',
  },
  {
    value: '1.3',
    label: 'TourismAndRecreationAreas',
    threatType: '1',
  },
  {
    value: '2.1',
    label: 'AnnualAndPerennialNonTimberCorps',
    threatType: '2',
  },
  {
    value: '2.2',
    label: 'WoodPulpPlantation',
    threatType: '2',
  },
  {
    value: '2.3',
    label: 'LivestockFarmingAndRanching',
    threatType: '2',
  },
  {
    value: '2.4',
    label: 'MarineAndFreshwaterAquaculture',
    threatType: '2',
  },
  {
    value: '3.1',
    label: 'OilAndGasQuarrying',
    threatType: '3',
  },
  {
    value: '3.2',
    label: 'MiningAndQuarrying',
    threatType: '3',
  },
  {
    value: '3.3',
    label: 'RenewableEnergy',
    threatType: '3',
  },
  {
    value: '4.1',
    label: 'RoadsAndRailroads',
    threatType: '4',
  },
  {
    value: '4.2',
    label: 'UtilityAndServicesLines',
    threatType: '4',
  },
  {
    value: '4.3',
    label: 'ShippingLanes',
    threatType: '4',
  },
  {
    value: '4.4',
    label: 'FlightPath',
    threatType: '4',
  },
  {
    value: '5.1',
    label: 'HuntingAndCollectingTerrestrialAnimals',
    threatType: '5',
  },
  {
    value: '5.2',
    label: 'GatheringTerrestrialPlants',
    threatType: '5',
  },
  {
    value: '5.3',
    label: 'LoggingAndWoodHarvesting',
    threatType: '5',
  },
  {
    value: '5.4',
    label: 'FishingAndHarvestingAquaticResources',
    threatType: '5',
  },
  {
    value: '6.1',
    label: 'RecreationalActivities',
    threatType: '6',
  },
  {
    value: '6.2',
    label: 'WarCivilUnrestAndMilitaryExercises',
    threatType: '6',
  },
  {
    value: '6.3',
    label: 'WorkAndOtherActivities',
    threatType: '6',
  },
  {
    value: '7.1',
    label: 'FireAndFireSuppression',
    threatType: '7',
  },
  {
    value: '7.2',
    label: 'DamAndWaterManagementUse',
    threatType: '7',
  },
  {
    value: '7.3',
    label: 'OtherEcosystemModifications',
    threatType: '7',
  },
  {
    value: '8.1',
    label: 'InvasiveNonNativeAlienSpeciesDisease',
    threatType: '8',
  },
  {
    value: '8.2',
    label: 'ProblematicNativeSpeciesDiseases',
    threatType: '8',
  },
  {
    value: '8.3',
    label: 'IntroducedGeneticMaterial',
    threatType: '8',
  },
  {
    value: '8.4',
    label: 'ProblematicSpeciesDiseasesOfUnknownOrigin',
    threatType: '8',
  },
  {
    value: '8.5',
    label: 'ViralPrionInducedDisease',
    threatType: '8',
  },
  {
    value: '8.6',
    label: 'DiseaseOfUnknownCause',
    threatType: '8',
  },
  {
    value: '9.1',
    label: 'DomesticAndUrbanWasteWater',
    threatType: '9',
  },
  {
    value: '9.2',
    label: 'IndustrialAndMilitaryEffluents',
    threatType: '9',
  },
  {
    value: '9.3',
    label: 'AgricultureAndForestryEffluents',
    threatType: '9',
  },
  {
    value: '9.4',
    label: 'GarbageAndSolidWaste',
    threatType: '9',
  },
  {
    value: '9.5',
    label: 'AirBornePollutants',
    threatType: '9',
  },
  {
    value: '9.6',
    label: 'ExcessEnergy',
    threatType: '9',
  },
  {
    value: '10.1',
    label: 'Volcanoes',
    threatType: '10',
  },
  {
    value: '10.2',
    label: 'EarthquakesTsunamis',
    threatType: '10',
  },
  {
    value: '10.3',
    label: 'AvalanchesLandslides',
    threatType: '10',
  },
  {
    value: '11.1',
    label: 'HabitatShiftingAndAlteration',
    threatType: '11',
  },
  {
    value: '11.2',
    label: 'Droughts',
    threatType: '11',
  },
  {
    value: '11.3',
    label: 'TemperatureExtremes',
    threatType: '11',
  },
  {
    value: '11.4',
    label: 'StormsAndFlooding',
    threatType: '11',
  },
  {
    value: '11.5',
    label: 'OtherImpacts',
    threatType: '11',
  },
  {
    value: '12.1',
    label: 'OtherThreat',
    threatType: '12',
  },
];

export const threatEnums = {
  Negligible: 0,
  SmallArea: 1,
  MostOfArea: 2,
  WholeOfSite: 3,
  Slow: 1,
  Moderate: 2,
  Rapid: 3,
  InThePast: 0,
  LongTermFuture: 1,
  NearFuture: 2,
  HappeningNow: 3,
};

export const COVERAGETYPE = [
  {
    value: 'MoreThan90',
    id: 0,
  },
  {
    value: 'Between50And90',
    id: 1,
  },
  {
    value: 'Between10And49',
    id: 2,
  },
  {
    value: 'LessThan10',
    id: 3,
  },
];

export const MANAGMENT_PLAN_TYPE = [
  {
    value: 'ComprehensiveAndCarriedOut',
    id: 0,
  },
  {
    value: 'ExistsButOutOfdate',
    id: 1,
  },
  {
    value: 'NotExistsButPlanStarted',
    id: 2,
  },
  {
    value: 'NotExists',
    id: 3,
  },
];

export const CONSERVATION_ACTIVITY_TYPE = [
  {
    value: 'EffectiveAndComprehensive',
    id: 0,
  },
  {
    value: 'ImportantButIncomplete',
    id: 1,
  },
  {
    value: 'Limited',
    id: 2,
  },
  {
    value: 'NoAction',
    id: 3,
  },
];

export const ANIMAL_UNITS = [
  {
    value: 'Pairs',
    id: 1,
  },
  {
    value: 'Individuals',
    id: 2,
  },
];

export const HABITAT_TYPE = [
  {
    value: 'ArtificialAquatic',
    id: 1,
  },
  {
    value: 'ArtificialTerrestrial',
    id: 2,
  },
  {
    value: 'CavesAndSubTerraneanHabitatsNonAquatic',
    id: 3,
  },
  {
    value: 'Desert',
    id: 4,
  },
  {
    value: 'Forest',
    id: 5,
  },
  {
    value: 'Grassland',
    id: 6,
  },
  {
    value: 'IntroducedVegetation',
    id: 7,
  },
  {
    value: 'MarinecoastalOrSupratidal',
    id: 8,
  },
  {
    value: 'MarinedeepoceanFloorBenthicAndDemersal',
    id: 9,
  },
  {
    value: 'MarineIntertidal',
    id: 10,
  },
  {
    value: 'MarineNeriticNearShoreShelf',
    id: 11,
  },
  {
    value: 'MarineOceanic',
    id: 12,
  },
  {
    value: 'Other',
    id: 13,
  },
  {
    value: 'Pairs',
    id: 14,
  },
  {
    value: 'RockyAreas',
    id: 15,
  },
  {
    value: 'Savanna',
    id: 16,
  },
  {
    value: 'Shrubland',
    id: 17,
  },
  {
    value: 'WetlandsInland',
    id: 20,
  },
  {
    value: 'Unknown',
    id: 21,
  },
];

export const OCCUPANCY_PERCENTAGE = [
  {
    value: 'GoodMoreThan90Percent',
    id: 1,
  },
  {
    value: 'ModerateBetween70To90',
    id: 2,
  },
  {
    value: 'PoorBetween40To70',
    id: 3,
  },
  {
    value: 'VeryPoorLessThan40',
    id: 4,
  },
];

export const HABITAT_QUALITY = [
  {
    value: 'Good',
    id: 1,
  },
  {
    value: 'Moderate',
    id: 2,
  },
  {
    value: 'Poor',
    id: 3,
  },
  {
    value: 'VeryPoor',
    id: 4,
  },
];

export const STATE_CONDITION = [
  {
    value: 0,
    label: 'VeryUnfavorable',
  },
  {
    value: 1,
    label: 'Unfavorable',
  },
  {
    value: 2,
    label: 'NearFavorable',
  },
  {
    value: 3,
    label: 'Favorable',
  },
];

export const IBA_CONSERVATION = [
  {
    value: 0,
    label: 'NegligibleOrNill',
  },
  {
    value: 1,
    label: 'Low',
  },
  {
    value: 2,
    label: 'Medium',
  },
  {
    value: 3,
    label: 'High',
  },
];
