import { createReducer } from '@reduxjs/toolkit';
import threat from '../actions/threat';

const sortThreat = (a, b) => {
  return a.id - b.id;
};

const initialState = {
  allThreats: [],
  userThreats: [],
  searchThreats: [],
  selectedThreat: null,
  isThreatDialogOpen: false,
  isThreatValidationDialogOpen: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(threat.setUserThreats, (state, action) => {
      state.userThreats = action.payload.sort(sortThreat);
    })
    .addCase(threat.setThreats, (state, action) => {
      state.allThreats = action.payload.sort(sortThreat);
    })
    .addCase(threat.setCreateThreat, (state, action) => {
      state.allThreats = [...state.allThreats, action.payload].sort(sortThreat);
      state.userThreats = [...state.userThreats, action.payload].sort(sortThreat);
    })
    .addCase(threat.setDeleteThreat, (state, action) => {
      state.allThreats = [...state.allThreats.filter((t) => t.id !== action.payload)].sort(sortThreat);
      state.userThreats = [...state.userThreats.filter((t) => t.id !== action.payload)].sort(sortThreat);
    })
    .addCase(threat.setUpdateThreat, (state, action) => {
      state.allThreats = [...state.allThreats.filter((t) => t.id !== action.payload.id), action.payload].sort(
        sortThreat,
      );
      state.userThreats = [
        ...state.userThreats.filter((t) => t.id !== action.payload.id),
        action.payload,
      ].sort(sortThreat);
    })
    .addCase(threat.setThreatSearch, (state, action) => {
      state.searchThreats = action.payload.sort(sortThreat);
    })
    .addCase(threat.setSelectedThreat, (state, action) => {
      state.selectedThreat = action.payload;
    })
    .addCase(threat.setIsThreatDialogOpen, (state, action) => {
      state.isThreatDialogOpen = action.payload;
    })
    .addCase(threat.setIsThreatValidationDialogOpen, (state, action) => {
      state.isThreatValidationDialogOpen = action.payload;
    }),
);
