import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import user from '../redux/actions/user';
import { handleLogout } from '../utils/auth';
function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    handleLogout();
    dispatch(user.logout());
  }, [dispatch]);
  return <></>;
}

export default Logout;
