import { COUNTRIES } from './../../constants/index';
const { createSlice } = require('@reduxjs/toolkit');

const charts = createSlice({
  name: 'charts',
  initialState: {
    country: COUNTRIES[2],
  },
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});

export default charts;
