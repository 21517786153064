import { createReducer } from '@reduxjs/toolkit';
import annualAssessment from '../actions/annualAssessment';
import _ from 'lodash';
const formState = {
  id: '',
  name: '',
  email: '',
  error: '',
  year: {
    code: '',
  },
  iba: null,
  threats: [],
  ibaSpeciesList: [],
  habitatList: [],
  siteLegalProtection: '',
  managmentPlanInfo: '',
  conservationActivity: '',
  protectionOrganizationAnswer: '',
  relationshipBetweenSeo: '',
  activitiesDoneByOrg: '',
  qualityOfAssessment: '',
};

const initialState = {
  form: formState,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(annualAssessment.reset, (state, _action) => {
      state.form = formState;
    })
    .addCase(annualAssessment.setFrom, (state, action) => {
      state.form = action.payload;
    })
    .addCase(annualAssessment.setName, (state, action) => {
      state.form.name = action.payload;
    })
    .addCase(annualAssessment.setEmail, (state, action) => {
      state.form.email = action.payload;
    })
    .addCase(annualAssessment.setIba, (state, action) => {
      state.form.iba = action.payload;
    })
    .addCase(annualAssessment.setThreats, (state, action) => {
      state.form.threats = action.payload;
    })
    .addCase(annualAssessment.setError, (state, action) => {
      state.form.error = action.payload;
    })
    .addCase(annualAssessment.setYear, (state, action) => {
      state.form.year = action.payload;
    })
    .addCase(annualAssessment.setIbaSpeciesList, (state, action) => {
      state.form.ibaSpeciesList = action.payload;
    })
    .addCase(annualAssessment.addSpeciesList, (state, action) => {
      state.form.ibaSpeciesList = [...state.form.ibaSpeciesList, action.payload];
    })
    .addCase(annualAssessment.updateSpeciesList, (state, action) => {
      var newList = [...state.form.ibaSpeciesList.filter((t) => t.id !== action.payload.id), action.payload];
      state.form.ibaSpeciesList = newList;
    })
    .addCase(annualAssessment.removeSpeciesList, (state, action) => {
      var newList = _.remove(state.form.ibaSpeciesList, (t) => t.id !== action.payload.id);
      state.form.ibaSpeciesList = newList;
    })
    .addCase(annualAssessment.setHabitatList, (state, action) => {
      state.form.habitatList = action.payload;
    })
    .addCase(annualAssessment.addHabitatList, (state, action) => {
      state.form.habitatList = [...state.form.habitatList, action.payload];
    })
    .addCase(annualAssessment.updateHabitatList, (state, action) => {
      var newList = [...state.form.habitatList.filter((t) => t.id !== action.payload.id), action.payload];
      state.form.habitatList = newList;
    })
    .addCase(annualAssessment.removeHabitatList, (state, action) => {
      var newList = _.remove(state.form.habitatList, (t) => t.id !== action.payload.id);
      state.form.habitatList = newList;
    })
    .addCase(annualAssessment.setSiteLegalProtection, (state, action) => {
      state.form.siteLegalProtection = action.payload;
    })
    .addCase(annualAssessment.setManagmentPlanInfo, (state, action) => {
      state.form.managmentPlanInfo = action.payload;
    })
    .addCase(annualAssessment.setConservationActivity, (state, action) => {
      state.form.conservationActivity = action.payload;
    })
    .addCase(annualAssessment.setProtectionOrganizationAnswer, (state, action) => {
      state.form.protectionOrganizationAnswer = action.payload;
    })
    .addCase(annualAssessment.setRelationshipBetweenSeo, (state, action) => {
      state.form.relationshipBetweenSeo = action.payload;
    })
    .addCase(annualAssessment.setActivitiesDoneByOrg, (state, action) => {
      state.form.activitiesDoneByOrg = action.payload;
    })
    .addCase(annualAssessment.setQualityOfAssessment, (state, action) => {
      state.form.qualityOfAssessment = action.payload;
    }),
);
