import React from 'react';
import i18n from 'i18next';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { QUALITY_OF_ASSESSMENT, IMPACT, IBA_CONSERVATION } from '../../../constants';
import { getStatusColor } from '../../../utils/threatUtil';
import { getThreatStatus, getConservationActionStatus } from './../../../utils/threatUtil';
import annualAssessment from '../../../redux/actions/annualAssessment';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function ScreenSix() {
  const classes = useStyles();
  var dispatch = useDispatch();
  var name = useSelector((state) => state?.annualAssessment?.form?.name);
  var email = useSelector((state) => state?.annualAssessment?.form?.email);
  var iba = useSelector((state) => state?.annualAssessment?.form?.iba);
  var year = useSelector((state) => state?.annualAssessment?.form?.year) || 0;
  var threats = useSelector((state) =>
    state?.annualAssessment?.form?.threats.map((o) => ({ ...o, tableData: {} })),
  );
  var currentTenantName = useSelector((state) => state?.appConfig?.currentTenant?.name);
  const siteLegalProtection = useSelector((state) => state.annualAssessment.form?.siteLegalProtection);
  const managmentPlanInfo = useSelector((state) => state.annualAssessment.form?.managmentPlanInfo);
  const conservationActivity = useSelector((state) => state.annualAssessment.form?.conservationActivity);
  const threatStatus = getThreatStatus(threats);
  const conservationActionStatus = getConservationActionStatus(
    siteLegalProtection,
    managmentPlanInfo,
    conservationActivity,
  );
  const qualityOfAssessment = useSelector((state) => state.annualAssessment.form?.qualityOfAssessment);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('Name')}:
          </Typography>
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('Email')}:
          </Typography>
          <Typography variant="h5" component="h2">
            {email}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('Country')}:
          </Typography>
          <Typography variant="h5" component="h2">
            {i18n.t(`${currentTenantName}`)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('IBA')}:
          </Typography>
          <Typography variant="h5" component="h2">
            {`${iba && iba.code ? iba.code + '.' : ''} ${i18n.t(
              iba && iba.nationalName ? iba.nationalName : '',
            )}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('Year_Of_Assessment')}:
          </Typography>
          <Typography variant="h5" component="h2">
            {year ? year.code : ''}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('Screen2Title')}:
          </Typography>
          <Typography variant="h5" component="h2">
            <div className="field-value">
              {i18n.t(threatStatus)}
              <i
                className="fa fa-exclamation-circle"
                style={{
                  marginLeft: '6px',
                  color:
                    threatStatus === 'Unknown'
                      ? 'white'
                      : getStatusColor(IMPACT.find((x) => x.label === threatStatus).value),
                }}
              />
            </div>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('ConservationAction')}:
          </Typography>
          <Typography variant="h5" component="h2">
            <div className="field-value">
              {i18n.t(conservationActionStatus)}
              <i
                className="fa fa-exclamation-circle"
                style={{
                  marginLeft: '6px',
                  color: getStatusColor(
                    IBA_CONSERVATION.length -
                      1 -
                      IBA_CONSERVATION.find((x) => x.label === conservationActionStatus).value,
                  ),
                }}
              />
            </div>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {i18n.t('Quality_Of_Assessment')}:
          </Typography>
          <RadioGroup
            name="Quality_Of_Assessment"
            value={qualityOfAssessment}
            onChange={(e) => dispatch(annualAssessment.setQualityOfAssessment(e.target.value))}
          >
            {QUALITY_OF_ASSESSMENT.map((x, i) => (
              <FormControlLabel
                key={i}
                value={x.value}
                control={<Radio />}
                label={<label className="label">{i18n.t(x.value)}</label>}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>

      {/* <Grid container direction="row" justify="flex-start" spacing={8}>
        <Grid item xs={2} className="field-lable">
          {i18n.t('Condition')}:
        </Grid>
        <Grid item xs={3}>
          <div className="field-value">
            {i18n.t(condition)}
            <i
              className="fa fa-exclamation-circle"
              style={{
                marginLeft: '6px',
                color:
                  condition === 'n/a'
                    ? 'white'
                    : this.GetStatusColor(
                        STATE_CONDITION.length - 1 - STATE_CONDITION.find((x) => x.label === condition).value,
                      ),
              }}
            />
          </div>
        </Grid>
      </Grid> */}
      {/* <Grid container direction="row" justify="flex-start" spacing={8}>
        <Grid item xs={2} className="field-lable">
          {i18n.t('ConservationAction')}:
        </Grid>
        <Grid item xs={3}>
          <div className="field-value">
            {i18n.t(conservationAction)}
            <i
              className="fa fa-exclamation-circle"
              style={{
                marginLeft: '6px',
                color: this.GetStatusColor(
                  IBA_CONSERVATION.length -
                    1 -
                    IBA_CONSERVATION.find((x) => x.label === conservationAction).value,
                ),
              }}
            />
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
}

export default ScreenSix;
