import loading from '../actions/loading';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import iba from '../actions/iba';
import { naturaAlertApi } from './../../api/index';

function* fetchIbaAsync(action) {
  yield put(loading.start({ key: 'fetchIbaAsync', opacity: 1 }));
  const data = yield call(naturaAlertApi.getIBAFiltered, { countryName: action.payload });
  yield put(iba.setIba(data));
  yield put(loading.stop({ key: 'fetchIbaAsync' }));
}

export default function* () {
  yield all([takeLatest(iba.fetchIbaAsync.type, fetchIbaAsync)]);
}
