import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './NewLayout.scss';
import NavBar from './../components/Navbar/Navbar';
import SnackBar from '../components/Snackbar/SnackBar';
function NewLayout(props) {
  return (
    <div>
      <CssBaseline />
      <NavBar />
      <main>
        <div>{props.children}</div>
      </main>
      <SnackBar />
    </div>
  );
}

export default NewLayout;
