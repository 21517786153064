import React from 'react';
import i18n from '../../i18n';
import Done from '@material-ui/icons/Done';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import threatAction from '../../redux/actions/threat';
import swal from '@sweetalert/with-react';
import map from '../../redux/slices/map';
import { Visibility } from '@material-ui/icons';
const ThreatItem = ({ threat, isValidation }) => {
  var dispatch = useDispatch();
  var isAdmin = useSelector((state) => state?.user?.isAdmin);
  var sub = useSelector((state) => state?.user?.sub);

  const isOwn = sub === threat.creatorId;

  const setSelectedThreat = (data) => {
    dispatch(map.actions.setSelectedThreat(data));
  };

  const openValidationDialog = () => {
    dispatch(map.actions.setIsThreatValidationDialogOpen(true));
  };

  const deleteThreat = () => {
    swal({
      title: i18n.t('AreYouSure'),
      text: 'Once deleted, you will not be able to recover this threat!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(threatAction.deleteThreatAsync(threat.id));
        dispatch(map.actions.setIsThreatDialogOpen(false));
      }
    });
  };

  const editThreat = (threat) => {
    setSelectedThreat(threat);
    dispatch(map.actions.setIsThreatDialogOpen(true));
  };

  return (
    <ListItem key={threat.id} button onClick={() => setSelectedThreat(threat)}>
      {isAdmin || isOwn ? (
        <Box>
          <ListItemText
            primary={<Typography className="text-base">{i18n.t(threat.type)}</Typography>}
            secondary={<Typography>{i18n.t(threat.subType)}</Typography>}
          ></ListItemText>
          <ListItemSecondaryAction>
            {(!isOwn || isValidation) && (
              <IconButton onClick={() => openValidationDialog()}>
                {threat.isValid ? (
                  <Done fontSize="large" variant="contained" color="secondary" />
                ) : (
                  <HighlightOff
                    fontSize="large"
                    variant="contained"
                    style={{
                      color: 'red',
                    }}
                  />
                )}
              </IconButton>
            )}
            <IconButton aria-label="Delete" onClick={() => editThreat(threat)}>
              <Visibility fontSize="large" variant="contained" color="secondary" />
            </IconButton>
            <IconButton aria-label="Delete" onClick={() => deleteThreat()}>
              <DeleteIcon fontSize="large" variant="contained" color="secondary" />
            </IconButton>
          </ListItemSecondaryAction>
        </Box>
      ) : (
        <ListItemText
          primary={<Typography className="text-base">{i18n.t(threat.type)}</Typography>}
          secondary={<Typography>{i18n.t(threat.subType)}</Typography>}
        ></ListItemText>
      )}
    </ListItem>
  );
};

export default ThreatItem;
