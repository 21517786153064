import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import MaterialTable from 'material-table';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ANIMAL_UNITS } from './../../../constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import annualAssessment from './../../../redux/actions/annualAssessment';
import { naturaAlertApi } from '../../../api';

export default function ScreenTwo() {
  var iba = useSelector((state) => state?.annualAssessment?.form?.iba);
  var ibaSpeciesList = useSelector((state) =>
    state?.annualAssessment?.form?.ibaSpeciesList.map((o) => ({ ...o, tableData: {} })),
  );
  var dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (iba && iba.id) {
      (async () => {
        const res = await naturaAlertApi.getAllIbaSpecies(iba.id);
        setOptions(res);
      })();
    }
  }, [iba]);

  return (
    <div>
      <MaterialTable
        title=""
        columns={[
          {
            title: i18n.t('ScientificName'),
            field: 'keySpeciesAtSite',
            render: (rowData) => `${rowData.keySpeciesAtSite.speciesBiologicalName}`,
            editComponent: (props) => (
              <Autocomplete
                options={options}
                value={options.find((x) => x.speciesBiologicalName === props.value)}
                getOptionLabel={(option) => `${option.speciesBiologicalName}`}
                onChange={(_event, newValue) => {
                  props.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={i18n.t('Key_Species_At_Site')} variant="outlined" />
                )}
              />
            ),
          },
          {
            title: i18n.t('Original_Population'),
            field: 'originalPopulationSize',
            type: 'numeric',
          },
          {
            title: i18n.t('Current_Population'),
            field: 'currentPopulationSize',
            type: 'numeric',
          },
          {
            title: i18n.t('Units'),
            field: 'units',
            editComponent: (props) => (
              <Autocomplete
                options={ANIMAL_UNITS}
                value={ANIMAL_UNITS.find((x) => x.value === props.value)}
                getOptionLabel={(option) => `${option.value}`}
                onChange={(_event, newValue) => {
                  props.onChange(newValue?.value);
                }}
                renderInput={(params) => <TextField {...params} label={i18n.t('Units')} variant="outlined" />}
              />
            ),
          },
          {
            title: i18n.t('Comments'),
            field: 'comments',
          },
        ]}
        data={ibaSpeciesList}
        options={{
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              newData.id = uuidv4();
              dispatch(annualAssessment.addSpeciesList(newData));
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              dispatch(annualAssessment.updateSpeciesList(newData));
              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              dispatch(annualAssessment.removeSpeciesList(oldData));
              resolve();
            }),
        }}
      />
    </div>
  );
}
