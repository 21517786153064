import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
//import './AboutPage.scss';
import { Trans } from 'react-i18next';
import FeedBack from '../components/Feedback';
import './About.scss';
import { Card, CardContent, CardHeader, CardActions, Divider } from '@material-ui/core';
import i18n from './../i18n';
const IMAGES = [
  './images/helpimgs/about-birds.jpg',
  './images/helpimgs/about-ducks.jpg',
  './images/helpimgs/about-geese.jpg',
];

class AboutPage extends Component {
  renderCarousel() {
    return (
      <Carousel autoPlay infiniteLoop showStatus={false} showIndicators={false} showThumbs={false}>
        {IMAGES.map((imgurl) => (
          <div key={imgurl}>
            <img src={imgurl} alt="" />
          </div>
        ))}
      </Carousel>
    );
  }

  render() {
    return (
      <div className="about-page">
        <div className="about-content">
          <div className="text-wrapper">
            <Card>
              <CardHeader title={i18n.t('about_us')}>
                <Trans i18nKey="about_us" />
              </CardHeader>
              <Divider />
              <CardContent>
                <div className="about-text">
                  <p className="">
                    <Trans i18nKey="AboutText3" />
                  </p>
                  <br></br>
                  <p>
                    <Trans i18nKey="AboutText4" />
                  </p>
                  <br></br>
                  <p>
                    <Trans i18nKey="AboutText5" />
                    <a href="http://datazone.birdlife.org/site/mapsearch" className="data-zone-link">
                      <Trans i18nKey="DataZone" />
                    </a>
                    <br />
                    <Trans i18nKey="CheckOurLatestMap" />
                    <a href="https://bit.ly/2wov0Ai" className="danger-zone-link">
                      <Trans i18nKey="IBAsInDanger" />
                    </a>
                  </p>
                  <br />
                  <CardActions>
                    <a href="/privacypolicy">
                      <Trans i18nKey="PrivacyPolicy" />
                    </a>
                    <a href="/terms">
                      <Trans i18nKey="TermsAndConditions" />
                    </a>
                  </CardActions>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <FeedBack />
      </div>
    );
  }
}

export default AboutPage;
