// TODO[burs]: ensure all colors are defined as variables

export const colorScheme = {
  borderColor: '#eaeaea',
  primary: {
    light: '#41877f',
    main: '#126a5f',
    dark: '#0c4a42',
    contrastText: '#fff',
  },
  secondary: {
    light: '#e7c45b',
    main: '#e1b633',
    dark: '#9d7f23',
    contrastText: '#000',
  },
};
