import React, { useState, useEffect } from 'react';
import { naturaAlertApi } from './../../api/index';
import { Pie } from '@nivo/pie';
import { THREAT_TYPE, threatEnums, SCOPE, SEVERITY, TIMING } from '../../constants';
import i18n from 'i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import IbaDropDown from './../IBA/IbaDropDown';
import Grid from '@material-ui/core/Grid';
export const PieChartColors = {
  0: 'rgb(229, 245, 224)',
  1: 'rgb(161, 217, 155)',
  2: 'rgb(65, 171, 93)',
  3: 'rgb(0, 109, 44)',
};
export const getSliceColor = (data) => PieChartColors[threatEnums[data.id]];

const Tooltip = (props) => (
  <div
    style={{
      color: '#126a5f',
      fontSize: '13px',
      textAlign: 'center',
      margin: '1px',
    }}
  >
    {i18n.t(props.label)}: {props.value}
  </div>
);

const PieChartWithLabel = ({ data, label }) => {
  if (!data || data.length === 0) {
    return null;
  }
  return (
    <div>
      <Pie
        width={500}
        height={300}
        margin={{
          top: 40,
          right: 40,
          bottom: 20,
          left: 40,
        }}
        data={data}
        tooltip={Tooltip}
        colorBy={getSliceColor}
        radialLabel={(d) => i18n.t(d.label)}
        // radialLabelsTextColor="#ccc"
        // radialLabelsLinkColor="#ccc"
        radialLabelsLinkDiagonalLength={15}
        radialLabelsLinkHorizontalLength={15}
      />
      <div className="pie-chart-caption">{i18n.t(label)}</div>
    </div>
  );
};

function PressureThreats() {
  const [scope, setScope] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [timing, setTiming] = useState(null);
  const [error, setError] = useState(null);
  const [type, setType] = useState(null);
  const [iba, setIba] = useState(null);
  useEffect(() => {
    (async () => {
      const payload = {};
      if (type) {
        payload['TypeCode'] = type.value;
      }

      if (iba) {
        payload['IBACode'] = iba.code;
      }
      naturaAlertApi
        .getIBAPressure(payload)
        .then((response) => {
          const scope = Object.keys(response.scope).map((k) => ({
            id: k,
            value: response.scope[k],
            label: SCOPE.find((s) => s.value === threatEnums[k]).label,
          }));

          const severity = Object.keys(response.severity).map((k) => ({
            id: k,
            value: response.severity[k],
            label: SEVERITY.find((s) => s.value === threatEnums[k]).label,
          }));

          const timing = Object.keys(response.timing).map((k) => ({
            id: k,
            value: response.timing[k],
            label: TIMING.find((s) => s.value === threatEnums[k]).label,
          }));

          const error = !(scope.length > 0 || timing.length > 0 || severity.length > 0)
            ? i18n.t('no_data_found')
            : null;
          setTiming(timing);
          setSeverity(severity);
          setError(error);
          setScope(scope);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, [iba, type]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={6} xl={6} xs={12}>
          <Autocomplete
            options={THREAT_TYPE}
            getOptionLabel={(option) => `${option.value}. ${i18n.t(option.label)}`}
            onChange={(_event, newValue) => {
              setType(newValue);
            }}
            value={type}
            renderInput={(params) => <TextField {...params} label={i18n.t('type')} variant="outlined" />}
          />
        </Grid>
        <Grid item lg={6} sm={6} xl={6} xs={12}>
          <IbaDropDown iba={iba} setIba={setIba} isSearch={false} />
        </Grid>
      </Grid>

      <div
        style={{
          fontSize: '13px',
          textAlign: 'center',
          margin: '1px',
        }}
      >
        {error ? (
          <div
            style={{
              fontSize: '13px',
              textAlign: 'center',
              paddingTop: 20,
              margin: '1px',
            }}
          >
            {error}
          </div>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs>
            <PieChartWithLabel data={scope} label="Scope" />
          </Grid>
          <Grid item xs>
            <PieChartWithLabel data={severity} label="Severity" />
          </Grid>
          <Grid item xs>
            <PieChartWithLabel data={timing} label="Timing" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PressureThreats;
