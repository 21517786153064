import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from 'i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import threat from '../../redux/actions/threat';
import map from '../../redux/slices/map';

const ValidationFrom = () => {
  const isOpen = useSelector((state) => state?.map?.isThreatValidationDialogOpen) || false;
  const isThreatValid = useSelector((state) => state?.map?.selectedThreat?.isValid) || false;
  const threatComment = useSelector((state) => state?.map?.selectedThreat?.validationMessage);
  const threatId = useSelector((state) => state?.map?.selectedThreat?.id);
  var selectedThreat = useSelector((state) => state?.map?.selectedThreat);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(isThreatValid);
  const [comment, setComment] = useState(threatComment);

  useEffect(() => {
    setComment(threatComment);
    setIsValid(isThreatValid);
  }, [isThreatValid, threatComment, selectedThreat]);

  const handleChange = (event) => {
    if (event.target.value === 'true') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const validate = () => {
    dispatch(
      threat.verifyThreatAsync({
        threatId,
        isValid,
        comment,
      }),
    );
    dispatch(map.actions.setIsThreatValidationDialogOpen(false));
    if (selectedThreat) {
      const updatedThreat = { ...selectedThreat, isValid: isValid, validationMessage: comment };
      dispatch(map.actions.setUpdateThreat(updatedThreat));
    }
  };

  const handleClose = () => {
    dispatch(map.actions.setIsThreatValidationDialogOpen(false));
  };

  return (
    <div className="ValidationFromWrapper">
      <Dialog
        style={{
          zIndex: '10000',
        }}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{i18n.t('Validate')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <DialogContentText>{i18n.t('ValidateMessage')}</DialogContentText>
            <RadioGroup aria-label="validation" name="validation" value={isValid} onChange={handleChange}>
              <FormControlLabel value={true} control={<Radio />} label={i18n.t('Valid')} />
              <FormControlLabel value={false} control={<Radio />} label={i18n.t('InValid')} />
            </RadioGroup>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label={i18n.t('Message')}
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {i18n.t('Cancel')}
          </Button>
          <Button variant="contained" onClick={validate} color="secondary">
            {i18n.t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ValidationFrom;
