import React from 'react';
// import Home from '@material-ui/icons/Home';
import Info from '@material-ui/icons/Info';
import Map from '@material-ui/icons/Map';
import Settings from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { NavLink, useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TableChartIcon from '@material-ui/icons/TableChart';
import { useSelector } from 'react-redux';
import { useFeature } from './../../hooks/useFeature';
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import i18n from 'i18next';
import useCookie from 'react-use-cookie';
function NavBar() {
  const [showIntro, setShowIntro] = useCookie('intro');

  if (showIntro === '') {
    setShowIntro('yes');
  }

  var loggedIn = useSelector((state) => state?.user?.loggedIn);
  let location = useLocation();
  const currentTenantAvailable = useSelector((state) => state?.appConfig?.currentTenant?.isAvailable);
  const isAuthenticated = useSelector((state) => state?.user?.loggedIn);
  const isAdmin = useSelector((state) => state?.user?.isAdmin);

  const menus = [
    {
      link: '/explore',
      icon: <Map />,
      text: i18n.t('Map'),
      className: 'map',
      isVisible: true,
    },
    {
      link: '/charts',
      icon: <ShowChartIcon />,
      text: i18n.t('Charts'),
      className: 'charts',
      isVisible: true,
    },

    {
      link: '/iba',
      icon: <TableChartIcon />,
      text: i18n.t('IBA'),
      className: 'iba',
      isVisible: useFeature('NaturaAlert.Iba') && currentTenantAvailable && isAuthenticated && isAdmin,
    },
    {
      link: '/assessment',
      icon: <AssessmentIcon />,
      className: 'assessment',
      text: i18n.t('Annual Assessment'),
      isVisible:
        useFeature('NaturaAlert.AnnualAssessments') && currentTenantAvailable && isAuthenticated && isAdmin,
    },
  ];

  const bottomMenu = [
    {
      link: '/about',
      icon: <Info />,
      text: i18n.t('About'),
      className: 'about',
      isVisible: true,
    },
    {
      link: '/profile',
      icon: <Settings />,
      text: i18n.t('Settings'),
      className: 'profile',
      isVisible: useFeature('NaturaAlert.Profile') && isAuthenticated,
    },
    {
      link: '/login',
      icon: <AccountBoxIcon color="secondary" />,
      text: i18n.t('Login'),
      className: 'login',
      isVisible: !loggedIn,
    },
    {
      link: '/logout',
      className: 'logout',
      icon: <ExitToAppIcon color="secondary" />,
      text: i18n.t('Logout'),
      isVisible: loggedIn,
    },
  ];

  const steps = [
    {
      element: '.map',
      intro: 'Map',
    },
    {
      element: '.charts',
      intro: 'Chart',
    },
    {
      element: '.login',
      intro: 'Login',
    },
  ];

  return (
    <nav className="navbar">
      <Steps enabled={showIntro === 'yes'} steps={steps} initialStep={0} onExit={() => setShowIntro('no')} />
      <ul className="navbar-nav">
        <li className="logo" key="logo">
          <NavLink to="/" className="nav-link-logo">
            <img src="/images/logo_no_text.png" height="50" width="50" alt="logo"></img>
          </NavLink>
        </li>
        {menus
          .filter((x) => x.isVisible)
          .map((menu) => {
            return (
              <Tooltip title={menu.text} placement="right" key={menu.link}>
                <li
                  key={menu.link}
                  className={
                    location && location.pathname === menu.link
                      ? 'nav-item-selected ' + menu.className
                      : 'nav-item ' + menu.className
                  }
                >
                  <NavLink to={menu.link} key={menu.link}>
                    <div className="nav-link">{menu.icon}</div>
                  </NavLink>
                </li>
              </Tooltip>
            );
          })}
        <li className="navbar-spacer"></li>
        {bottomMenu
          .filter((x) => x.isVisible)
          .map((menu) => {
            return (
              <Tooltip title={menu.text} placement="right" key={menu.link}>
                <li
                  key={menu.link}
                  className={
                    location && location.pathname === menu.link
                      ? 'nav-item-selected ' + menu.className
                      : 'nav-item ' + menu.className
                  }
                >
                  <NavLink to={menu.link} key={menu.link}>
                    <div className="nav-link">{menu.icon}</div>
                  </NavLink>
                </li>
              </Tooltip>
            );
          })}
      </ul>
    </nav>
  );
}

export default NavBar;
