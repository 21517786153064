import { naturaAlertApi } from '../../api/index';
import loading from '../actions/loading';
import appConfig from '../actions/appConfig';
import { call, put, takeLatest, all } from 'redux-saga/effects';

function* fetchAppConfig() {
  yield put(loading.start({ key: 'appConfig', opacity: 1 }));
  const data = yield call(naturaAlertApi.getAppConfig);
  yield put(appConfig.setAppConfig(data));
  yield put(loading.stop({ key: 'appConfig' }));
}

export default function* () {
  yield all([takeLatest(appConfig.fetchAppConfigAsync.type, fetchAppConfig)]);
}
