import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { COVERAGETYPE, MANAGMENT_PLAN_TYPE, CONSERVATION_ACTIVITY_TYPE } from '../../../constants';
import i18n from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import annualAssessment from './../../../redux/actions/annualAssessment';

function ScreenFour() {
  const siteLegalProtection = useSelector((state) => state.annualAssessment.form?.siteLegalProtection);
  const managmentPlanInfo = useSelector((state) => state.annualAssessment.form?.managmentPlanInfo);
  const conservationActivity = useSelector((state) => state.annualAssessment.form?.conservationActivity);
  const dispatch = useDispatch();
  return (
    <div>
      <FormControl>
        <div>{i18n.t('Screen5Q1')}</div>
        <RadioGroup
          name="SiteLegalProtection"
          value={siteLegalProtection}
          onChange={(e) => dispatch(annualAssessment.setSiteLegalProtection(e.target.value))}
        >
          {COVERAGETYPE.map((x, i) => (
            <FormControlLabel
              key={i}
              value={x.value}
              control={<Radio />}
              label={<label className="label">{i18n.t('Screen5Q1A' + (i + 1))}</label>}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl>
        <div>{i18n.t('Screen5Q2')}</div>
        <RadioGroup
          name="managmentPlanInfo"
          value={managmentPlanInfo}
          onChange={(e) => dispatch(annualAssessment.setManagmentPlanInfo(e.target.value))}
        >
          {MANAGMENT_PLAN_TYPE.map((x, i) => (
            <FormControlLabel
              key={i}
              value={x.value}
              control={<Radio />}
              label={<label className="label">{i18n.t('Screen5Q2A' + (i + 1))}</label>}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl>
        <div>{i18n.t('Screen5Q3')}</div>
        <RadioGroup
          name="conservationActivity"
          value={conservationActivity}
          onChange={(e) => dispatch(annualAssessment.setConservationActivity(e.target.value))}
        >
          {CONSERVATION_ACTIVITY_TYPE.map((x, i) => (
            <FormControlLabel
              key={i}
              value={x.value}
              control={<Radio />}
              label={<label className="label">{i18n.t('Screen5Q3A' + (i + 1))}</label>}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default ScreenFour;
