import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import { ASSESSMENT_YEARS_LIST, THREAT_TYPE, TIMING, SCOPE, SEVERITY } from '../../../constants';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import { naturaAlertApi } from './../../../api/index';
import { computeImpact } from '../../../utils/threatUtil';
import { THREAT_SUBTYPE } from './../../../constants/index';
import annualAssessment from './../../../redux/actions/annualAssessment';
import IbaDropDown from '../../IBA/IbaDropDown';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {
    width: '100%',
    display: 'flex',
    paddingBottom: '40px',
    textAlign: 'center',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: '10px',
    width: '40%',
  },
  table: {
    width: '100%',
  },
}));
export default function ScreenOne() {
  const classes = useStyles();
  var dispatch = useDispatch();
  var name = useSelector((state) => state?.annualAssessment?.form?.name);
  var email = useSelector((state) => state?.annualAssessment?.form?.email);
  var iba = useSelector((state) => state?.annualAssessment?.form?.iba);
  var year = useSelector((state) => state?.annualAssessment?.form?.year) || 0;
  var threats = useSelector((state) =>
    state?.annualAssessment?.form?.threats.map((o) => ({ ...o, tableData: {} })),
  );

  useEffect(() => {
    if (name && email && iba && year) {
      //console.log('valid');
    }
  }, [name, email, iba, year, threats]);

  useEffect(() => {
    if (iba) {
      naturaAlertApi.getValidThreatsWithYear(iba.code, year.code).then((res) => {
        if (res && res.length) {
          var threats = [];
          _.forEach(res, (threat) => {
            var impact = null;
            if (threat.scope !== null && threat.timing !== null && threat.severity !== null) {
              impact = computeImpact(threat.scope, threat.timing, threat.severity);
            }

            threats.push({
              id: threat.id,
              threatGuid: threat.id,
              subTypeCode: threat.subTypeCode,
              typeCode: threat.typeCode,
              timing: threat.timing,
              scope: threat.scope,
              severity: threat.severity,
              impact: impact !== null ? i18n.t(impact.label) : 'Unknown',
              comment: threat.comment,
            });
          });
          dispatch(annualAssessment.setThreats(threats));
          dispatch(annualAssessment.setIba(iba));
          setTableHead(iba.internationalName);
        } else {
          dispatch(annualAssessment.setThreats([]));
        }
      });
    } else {
      setTableHead('');
      dispatch(annualAssessment.setThreats([]));
    }
  }, [dispatch, iba, year]);

  const [tableHead, setTableHead] = useState('');
  const setIba = (newValue) => {
    dispatch(annualAssessment.setIba(newValue));
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.container}>
          <TextField
            required
            value={name}
            onChange={(e) => dispatch(annualAssessment.setName(e.target.value))}
            className={classes.textField}
            label={i18n.t('Name')}
          />
          <TextField
            required
            value={email}
            className={classes.textField}
            onChange={(e) => dispatch(annualAssessment.setEmail(e.target.value))}
            label={i18n.t('Email')}
          />
          <Autocomplete
            className={classes.textField}
            options={ASSESSMENT_YEARS_LIST}
            getOptionLabel={(option) => `${option.code}`}
            value={year}
            onChange={(_event, newValue) => {
              dispatch(annualAssessment.setYear(newValue));
            }}
            renderInput={(params) => (
              <TextField {...params} label={i18n.t('Year_Of_Assessment')} variant="outlined" />
            )}
          />
          <div className={classes.textField}>
            <IbaDropDown iba={iba} setIba={setIba} />
          </div>
        </div>
        <div className={classes.table}>
          <MaterialTable
            title={tableHead}
            columns={[
              {
                id: 0,
                title: i18n.t('ThreatCategory'),
                field: 'typeCode',
                render: (rowData) => i18n.t(THREAT_TYPE.find((y) => y.value === rowData.typeCode).label),
              },
              {
                id: 1,
                title: i18n.t('Threat'),
                field: 'subTypeCode',
                render: (rowData) =>
                  i18n.t(THREAT_SUBTYPE.find((y) => y.value === rowData.subTypeCode).label),
              },
              {
                id: 2,
                title: i18n.t('Timing'),
                field: 'timing',
                render: (rowData) =>
                  rowData.timing !== null ? i18n.t(TIMING.find((x) => x.value === rowData.timing).label) : '',
              },
              {
                id: 3,
                title: i18n.t('Scope'),
                field: 'scope',
                render: (rowData) =>
                  rowData.scope !== null ? i18n.t(SCOPE.find((x) => x.value === rowData.scope).label) : '',
              },
              {
                id: 4,
                title: i18n.t('Severity'),
                field: 'severity',
                render: (rowData) =>
                  rowData.severity !== null
                    ? i18n.t(SEVERITY.find((x) => x.value === rowData.severity).label)
                    : '',
              },
              {
                id: 5,
                title: i18n.t('Impact'),
                field: 'impact',
              },
            ]}
            data={threats}
          />
        </div>
      </div>
    </div>
  );
}
