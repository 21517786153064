import React, { useState } from 'react';
import { naturaAlertApi } from '../../api';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import i18n from 'i18next';
import CloudUpload from '@material-ui/icons/CloudUpload';
import './FileInput.scss';

// mode = "COMPACT" or "EXPANDED"

const FileInput = ({ addFileUrl, mode, readOnly }) => {
  const [saving, setSaving] = useState(false);
  const [isDraggedOver, setDraggingOver] = useState(false);

  function onDrop(files) {
    if (files.length > 0) {
      setSaving(true);
      naturaAlertApi.uploadFiles(files).then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((url) => {
            addFileUrl(url);
          });
        }
        setSaving(false);
      });
    }
  }

  function onDragEnter(e) {
    setDraggingOver(true);
  }

  function onDragLeave(e) {
    setDraggingOver(false);
  }

  const dragoverClass = isDraggedOver ? 'is-dragged-over' : 'is-not-dragged-over';
  const modeClass = mode === 'EXPANDED' ? 'mode-expanded' : 'mode-compact';

  return (
    <div className={`natura-dropzone ${dragoverClass} ${modeClass}`}>
      {!readOnly && (
        <Dropzone
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          // onDragOver={onDragOver}
          multiple={true}
          accept="image/*"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {saving ? (
                <i className="fa fa-spinner fa-spin fa-fw" />
              ) : (
                <div className="natura-dropzone-wrap">
                  <i className="natura-dropzone-bg-icon fa fa-upload"></i>

                  <div className="natura-main-drag-message">Drag the pictures here</div>
                  <div className="natura-secondary-drag-message">or</div>
                  <div>
                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUpload />}>
                      {i18n.t('Upload')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default FileInput;
