import React from 'react';
import { TIMING, SEVERITY, SCOPE, THREAT_TYPE, THREAT_SUBTYPE } from '../../constants';
import './ThreatForm.scss';
import Loader from '../Loader';
import FileInput from './FileInput';
import { ThreatImages } from './ThreatImages';
import i18n from 'i18next';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { computeImpact } from '../../utils/threatUtil';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  select: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ThreatForm = ({ threat, setThreat, isReadOnly, canCreateAndEdit }) => {
  const classes = useStyles();

  var isAdmin = useSelector((state) => state?.user?.isAdmin);

  const updateImpact = () => {
    try {
      var status = i18n.t('Low');
      status = computeImpact(threat.scope.value, threat.timing.value, threat.severity.value);
      setThreat((threat) => ({
        ...threat,
        impact: status,
      }));
    } catch {}
  };

  const onSelectChange = (id, option) => {
    if (id) {
      setThreat((threat) => ({
        ...threat,
        [id]: option,
        error: '',
      }));
      switch (id) {
        case 'type': {
          setThreat((threat) => ({ ...threat, subType: null }));
          break;
        }
        default:
      }
    }
    updateImpact();
  };

  const addFileUrl = (url) => {
    var file = {
      fileUrl: url,
    };
    setThreat((threat) => ({
      ...threat,
      fileUrls: [...threat.fileUrls, file],
    }));
  };

  const removeFileUrl = (file) => {
    setThreat((threat) => ({
      ...threat,
      fileUrls: [...threat.fileUrls.filter((u) => u.id !== file.id)],
    }));
  };

  const commentChange = (event) => {
    var comment = event?.target?.value;
    setThreat((threat) => ({ ...threat, comment: comment }));
  };

  return (
    <div className="threat-form">
      {threat.loading ? (
        <Loader loading={threat.loading} />
      ) : (
        // padding needed because of https://github.com/mui-org/material-ui/issues/7466
        <div className={classes.root} style={{ padding: 6 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {!threat.loading && (
                <div id="content">
                  <div id="form">
                    <Autocomplete
                      options={THREAT_TYPE}
                      getOptionLabel={(option) => `${option.value}. ${i18n.t(option.label)}`}
                      onChange={(_event, newValue) => {
                        onSelectChange('type', newValue);
                      }}
                      value={threat.type}
                      disabled={isReadOnly}
                      renderInput={(params) => (
                        <TextField {...params} label={i18n.t('type')} variant="outlined" />
                      )}
                    />
                    <br />
                    <Autocomplete
                      options={THREAT_SUBTYPE.filter(
                        (subType) => threat.type && subType.threatType === threat.type.value,
                      )}
                      getOptionLabel={(option) => `${option.value}. ${i18n.t(option.label)}`}
                      onChange={(_event, newValue) => {
                        onSelectChange('subType', newValue);
                      }}
                      disabled={isReadOnly}
                      value={threat.subType}
                      renderInput={(params) => (
                        <TextField {...params} label={i18n.t('subType')} variant="outlined" />
                      )}
                    />
                    <br />
                    <Autocomplete
                      options={SEVERITY}
                      getOptionLabel={(option) => `${i18n.t(option.label)}`}
                      onChange={(_event, newValue) => {
                        onSelectChange('severity', newValue);
                      }}
                      disabled={isReadOnly}
                      value={threat.severity}
                      renderInput={(params) => (
                        <TextField {...params} label={i18n.t('severity')} variant="outlined" />
                      )}
                    />
                    <br />
                    {threat.isInsideIBA ? (
                      <div>
                        <Autocomplete
                          options={TIMING}
                          getOptionLabel={(option) => `${i18n.t(option.label)}`}
                          onChange={(_event, newValue) => {
                            onSelectChange('timing', newValue);
                          }}
                          disabled={isReadOnly}
                          value={threat.timing}
                          renderInput={(params) => (
                            <TextField {...params} label={i18n.t('timing')} variant="outlined" />
                          )}
                        />
                        <br />
                        <Autocomplete
                          options={SCOPE}
                          getOptionLabel={(option) => `${i18n.t(option.label)}`}
                          onChange={(_event, newValue) => {
                            onSelectChange('scope', newValue);
                          }}
                          disabled={isReadOnly}
                          value={threat.scope}
                          renderInput={(params) => (
                            <TextField {...params} label={i18n.t('scope')} variant="outlined" />
                          )}
                        />
                      </div>
                    ) : null}
                    <br />
                    <TextField
                      label={i18n.t('Comments')}
                      multiline
                      style={{ width: '100%' }}
                      rows={4}
                      disabled={isReadOnly}
                      value={threat.comment}
                      onChange={commentChange}
                      variant="outlined"
                    />
                    <br />
                    {threat?.impact?.label ? (
                      <div className="p-4">
                        <label>
                          {i18n.t('Impact')}: {threat?.impact.label}
                        </label>
                      </div>
                    ) : null}
                    <div className="comments">
                      {isAdmin && threat.username ? (
                        <Typography>
                          {i18n.t('Username')} :{' '}
                          {threat.username ? (
                            <a href={'mailto:' + threat.username}>{threat.username}</a>
                          ) : (
                            i18n.t('Anonymous')
                          )}{' '}
                          {threat.countryName ? i18n.t(threat.countryName) : ''}
                        </Typography>
                      ) : null}
                    </div>
                    {threat.error ? (
                      <div className="threat-form-notification">
                        <i className="fa fa-exclamation-circle" style={{ marginRight: '6px' }} />
                        {threat.error}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <FileInput
                mode={threat.fileUrls && threat.fileUrls.length ? 'COMPACT' : 'EXPANDED'}
                addFileUrl={addFileUrl}
                readOnly={isReadOnly}
              />
              <ThreatImages fileUrls={threat.fileUrls} removeFileUrl={removeFileUrl} readOnly={isReadOnly} />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ThreatForm;
