import axios from 'axios';
import { getAccessToken } from '../utils/auth';
import { setupCache } from 'axios-cache-adapter';
const NATURA_ALERT_API_URL = process.env.REACT_APP_NATURA_ALERT_API_URL;
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const axiosInstance = axios.create({ timeout: 30000, adapter: cache.adapter });

const getHeaders = async () => {
  const accessToken = await getAccessToken();
  const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : '';
  return headers;
};

async function postFeedBack(params) {
  const res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/User/feedback`, params, {
    responseType: 'json',
  });
  return res.data;
}

async function getProfileInfo() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/profile/my`, {
    headers: await getHeaders(),
    responseType: 'json',
  });
  return res.data;
}

async function getAllTenant() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/api/app/tenant`, {
    headers: await getHeaders(),
    responseType: 'json',
  });
  return res.data;
}

async function postAnnualAssessment(data) {
  //todo test
  const res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/AnnualAssessment`, data, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function putAnnualAssessment(data, id) {
  //todo test
  const res = await axiosInstance.put(`${NATURA_ALERT_API_URL}/AnnualAssessment/${id}`, data, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function getAllAnnualAssessment(clearCache) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/AnnualAssessment`, {
    responseType: 'json',
    headers: await getHeaders(),
    clearCacheEntry: clearCache,
  });

  return res.data;
}

async function getAnnualAssessment(id) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/AnnualAssessment/${id}`, {
    responseType: 'json',
    headers: await getHeaders(),
  });

  return res.data;
}

async function deleteAnnualAssessment(id) {
  const res = await axiosInstance.delete(`${NATURA_ALERT_API_URL}/AnnualAssessment?id=${id}`, {
    responseType: 'json',
    headers: await getHeaders(),
  });

  return res.data;
}

async function getAllIbaSpecies(ibaid) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/ibaspecies/ibaId?ibaId=${ibaid}`, {
    responseType: 'json',
  });

  return res.data;
}

async function getAnnualAssessmentThreats(ibaCode) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats/valid/${ibaCode}`, {
    responseType: 'json',
    headers: await getHeaders(),
  });

  return res.data;
}

async function getValidThreatsWithYear(ibaCode, year) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats/valid/${ibaCode}/${year}`, {
    responseType: 'json',
    headers: await getHeaders(),
  });

  return res.data;
}

async function getAnnualAssessmentReportFiltered(params) {
  const res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/AnnualAssessment/ExportToExcel`, params, {
    responseType: 'blob',
    headers: await getHeaders(),
  });
  return res.data;
}

async function getIBAs() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/IBAs`, {
    responseType: 'json',
  });

  return res.data;
}

async function getIBAFiltered(params) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/IBAs/Filtered`, {
    responseType: 'json',
    params: params,
  });

  return res.data;
}

async function getThreats() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats`, {
    responseType: 'json',
  });

  return res.data;
}

async function getPagedIbas(params) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/IBAs/list`, {
    responseType: 'json',
    params: params,
    headers: await getHeaders(),
  });
  return res.data;
}

async function getAllDetailedThreats() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats/All`, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function getAppConfig() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/api/abp/application-configuration`, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function getThreatsFiltered(params) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats/Filtered`, {
    responseType: 'json',
    params: params,
  });

  return res.data;
}
//Pressure of particular IBA for a particular threat.
async function getIBAPressure(params) {
  const res = await axiosInstance.get(
    `${NATURA_ALERT_API_URL}/Statistics/IBAs/${params.IBACode}/CategoryCounts/${
      params.TypeCode ? params.TypeCode : '0'
    }`,
    {
      responseType: 'json',
    },
  );

  return res.data;
}

//Number of IBAs in danger for a particular country.
async function getIBAsWithThreatCount(params) {
  const res = await axiosInstance.get(
    `${NATURA_ALERT_API_URL}/Statistics/Countries/${params.tenantName}/IBAsWithThreatCount`,
    {
      responseType: 'json',
    },
  );

  return res.data;
}
//Number of IBAs by main threat (level 1).
async function getThreatsWithIBACount(params) {
  const res = await axiosInstance.get(
    `${NATURA_ALERT_API_URL}/Statistics/Countries/${params.tenantName}/ThreatsWithIBACount`,
    {
      responseType: 'json',
    },
  );

  return res.data;
}

//Impact of threats per IBA for a particular country.
async function getImpactCountPerIBA(params) {
  const res = await axiosInstance.get(
    `${NATURA_ALERT_API_URL}/Statistics/Countries/${params.tenantName}/ImpactCountPerIBA`,
    {
      responseType: 'json',
    },
  );

  return res.data;
}

//Impact of threats for a particular country.
async function getImpactCount(params) {
  const res = await axiosInstance.get(
    `${NATURA_ALERT_API_URL}/Statistics/Countries/${params.tenantName}/ImpactCount`,
    {
      responseType: 'json',
    },
  );

  return res.data;
}

async function getUser() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/api/app/User`, {
    responseType: 'json',
  });
  return res.data;
}

//get threats created by user
async function getUserThreats() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats/Filtered`, {
    responseType: 'json',
    params: { Own: true },
    headers: await getHeaders(),
  });
  return res.data;
}

// Terms accepted
async function postTermsAccepted(params) {
  const res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/api/app/User/termsAccepted`, params, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function getTermsAccepted() {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/api/app/User/termsAccepted`, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

//insert new threat
async function insertThreat(params) {
  const res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/Threats`, params, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

//update existing threat
async function updateThreat(id, params) {
  await axiosInstance.put(`${NATURA_ALERT_API_URL}/Threats/${id}`, params, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  const data = {
    id: id,
    latitude: params.location.latitude,
    longitude: params.location.longitude,
    typeCode: params.typeCode,
    subTypeCode: params.subTypeCode,
    created: params.location.timestamp,
  };
  return data;
}

async function verifyThreat(params) {
  const payload = {
    approved: params.isValid,
    comment: params.comment,
  };
  var res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/Threats/${params.threatId}/Verify`, payload, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

//insert new threat
async function getThreat(id) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats/${id}`, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function deleteThreat(id) {
  const res = await axiosInstance.delete(`${NATURA_ALERT_API_URL}/Threats/${id}`, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function downloadExcelExport(params) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/Threats/ExportAllThreat/`, {
    responseType: 'blob',
    params: params,
    headers: await getHeaders(),
  });
  return res.data;
}

async function uploadFiles(files) {
  var formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i], files[i].name);
  }
  const config = {
    headers: await getHeaders(),
    'Content-Type': 'multipart/form-data',
  };
  return await axiosInstance.post(`${NATURA_ALERT_API_URL}/Upload`, formData, config);
}

async function getIbaData(params) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/IBAs/Filtered`, {
    responseType: 'json',
    headers: await getHeaders(),
    params: params,
  });
  return res.data;
}

async function getCareTakersForIBA(params) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/IBAs/GetCareTakerByIbaId`, {
    responseType: 'json',
    headers: await getHeaders(),
    params: params,
  });
  return res.data;
}

async function getUsers(params) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/api/identity/users`, {
    responseType: 'json',
    headers: await getHeaders(),
    params: params,
  });
  return res.data;
}

async function addCareTakers(params) {
  const res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/IBAs/AddCareTakers`, params, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function removeCareTakers(params) {
  const res = await axiosInstance.post(`${NATURA_ALERT_API_URL}/IBAs/RemoveCareTakers`, params, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

async function getIbaByCaterTakerId(userId) {
  const res = await axiosInstance.get(`${NATURA_ALERT_API_URL}/IBAs/GetIbaByCaterTakerId?UserId=${userId}`, {
    responseType: 'json',
    headers: await getHeaders(),
  });
  return res.data;
}

export const naturaAlertApi = {
  getUser: getUser,
  getIBAs: getIBAs,
  getIBAFiltered: getIBAFiltered,
  getThreats: getThreats,
  getThreatsFiltered: getThreatsFiltered,
  getIBAPressure: getIBAPressure,
  getIBAsWithThreatCount: getIBAsWithThreatCount,
  getThreatsWithIBACount: getThreatsWithIBACount,
  getImpactCountPerIBA: getImpactCountPerIBA,
  getImpactCount: getImpactCount,
  getUserThreats: getUserThreats,
  insertThreat: insertThreat,
  updateThreat: updateThreat,
  getThreat: getThreat,
  deleteThreat: deleteThreat,
  verifyThreat: verifyThreat,
  getAllDetailedThreats: getAllDetailedThreats,
  postFeedBack: postFeedBack,
  postAnnualAssessment: postAnnualAssessment,
  putAnnualAssessment: putAnnualAssessment,
  postTermsAccepted: postTermsAccepted,
  getTermsAccepted: getTermsAccepted,
  downloadExcelExport: downloadExcelExport,
  getAllAnnualAssessment: getAllAnnualAssessment,
  getAnnualAssessment: getAnnualAssessment,
  getAnnualAssessmentReportFiltered: getAnnualAssessmentReportFiltered,
  getAllIbaSpecies: getAllIbaSpecies,
  getAnnualAssessmentThreats: getAnnualAssessmentThreats,
  uploadFiles: uploadFiles,
  getIbaData: getIbaData,
  getAppConfig,
  getCareTakersForIBA,
  getUsers,
  addCareTakers,
  removeCareTakers,
  getIbaByCaterTakerId,
  deleteAnnualAssessment,
  getValidThreatsWithYear,
  getPagedIbas,
  getProfileInfo,
  getAllTenant,
};
