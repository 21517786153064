import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import i18n from 'i18next';
export const ThreatImages = ({ fileUrls: files, removeFileUrl, readOnly }) => (
  <div id="images">
    <div className="image-carousel">
      {
        files && files.length > 0 ? (
          <Carousel autoPlay infiniteLoop showStatus={false} showIndicators={true} showThumbs={false}>
            {files.map((file, index) => {
              var imgurl = file.fileUrl;
              return (
                <div key={`${imgurl}-${index}`}>
                  {/(jpg|gif|png)$/.test(imgurl) && (
                    <a href={imgurl} target="_blank" rel="noopener noreferrer">
                      <div>
                        <img src={imgurl} alt="" />
                      </div>
                    </a>
                  )}
                  {/(jpg|gif|png|mp4|mp3|ogg)$/.test(imgurl) === false && (
                    <a href={imgurl} target="_blank" rel="noopener noreferrer">
                      <div>
                        <img src={imgurl} alt="" />
                      </div>
                    </a>
                  )}
                  {/(mp4|mp3|ogg)$/.test(imgurl) && (
                    <Player>
                      <source src={imgurl} />
                    </Player>
                  )}
                  {!readOnly && (
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="carousel-status"
                        onClick={() => removeFileUrl(file)}
                      >
                        {i18n.t('Remove')}
                        <DeleteIcon />
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          </Carousel>
        ) : null
        // <div>{i18n.t('NoUploadedImages')}</div>
      }
    </div>
  </div>
);
