import { createSelector } from 'reselect';

const getApp = (state) => state;

export function createAppConfigSelector() {
  return createSelector([getApp], (state) => state.appConfig);
}

export function createIsAuthenticatedSelector() {
  return createSelector([getApp], (state) => state.appConfig?.currentUser?.isAuthenticated);
}

export function createLanguageSelector() {
  return createSelector([getApp], (state) => state?.appConfig?.localization?.currentCulture);
}

export function createLanguagesSelector() {
  return createSelector([getApp], (state) => state?.appConfig?.localization?.languages);
}

export function createGrantedPolicySelector(key) {
  return createSelector([getApp], (state) => state?.appConfig?.auth?.grantedPolicies[key] ?? false);
}

export function createGrantedFeatureSelector(key) {
  return createSelector([getApp], (state) => state?.appConfig?.features?.values[key] === 'True');
}

export function createCurrentTenantAvailableSelector() {
  return createSelector([getApp], (state) => state?.appConfig?.currentTenant?.isAvailable);
}

export function createIBAsSelector() {
  return createSelector([getApp], (state) => state?.ibas);
}
