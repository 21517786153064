import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.scss';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import { Map as LeafLetMap, TileLayer, ZoomControl, LayersControl, WMSTileLayer } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';
import Control from 'react-leaflet-control';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import RefreshIcon from '@material-ui/icons/Refresh';
import Fab from '@material-ui/core/Fab';
import i18n from 'i18next';
// eslint-disable-next-line no-unused-vars
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { useDispatch, useSelector } from 'react-redux';
import map from '../../redux/slices/map';
import Sidebar from './Sidebar';
import threatsActions from '../../redux/actions/threat';
import ThreatDialog from './../ThreatForm/ThreatDialog';
import ValidationFrom from '../ValidationFrom/ValidationFrom';
import { getPopup } from '../../utils/threatUtil';
import SnackBar from './../Snackbar/SnackBar';
const { BaseLayer, Overlay } = LayersControl;
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const useStyles = makeStyles((theme) => ({
  container: {
    height: `100vh`,
    display: 'flex',
    flexDirection: 'row',
  },
  list: {
    width: '30%',
    // borderColor: 'teal',
    // borderWidth: '5px',
    // borderStyle: 'solid',
    transition: '1s',
  },
  listHidden: {
    width: '0%',
    transition: '1s',
    visibility: 'hidden',
  },
  map: {
    width: '100%',
    height: '100%',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  popup: {
    display: 'flex',
    fontSize: 15,
  },
}));

var markers = L.markerClusterGroup();

function Map() {
  const position = [48.2082, 16.3738];
  const classes = useStyles();
  const threats = useSelector((state) => state.map.threats);
  const isOpen = useSelector((state) => state.map.isOpen);
  const selectedThreat = useSelector((state) => state.map.selectedThreat);
  // const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const loggedIn = useSelector((state) => state?.user?.loggedIn);
  // const isCaretaker = useSelector((state) => state?.user?.isCaretaker);
  const dispatch = useDispatch();
  var mapRef = useRef(null);

  useEffect(() => {
    dispatch(threatsActions.fetchThreatAsync());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.leafletElement.invalidateSize({ pan: true, animate: true });
      }
    }, 1000);
  }, [isOpen]);

  const updateOpen = () => {
    dispatch(map.actions.setIsOpen(!isOpen));
  };

  const refreshMap = () => {
    dispatch(threatsActions.fetchThreatAsync());
  };

  useEffect(() => {
    if (mapRef.current && selectedThreat) {
      mapRef.current.leafletElement.setView([selectedThreat.latitude, selectedThreat.longitude], 15);
    }
    // return () => {
    //   console.log('Map -> Reset selectedThreat', selectedThreat);
    //   dispatch(map.actions.setSelectedThreat(null));
    // };
  }, [dispatch, selectedThreat]);

  useEffect(() => {
    if (mapRef.current && threats && threats.length) {
      markers.off('click');
      markers.clearLayers();
      mapRef.current.leafletElement.removeLayer(markers);
      threats.forEach((threat) => {
        var marker = L.marker(new L.LatLng(threat.latitude, threat.longitude), {
          title: threat.typeCode,
          threat: threat,
        });
        markers.addLayer(marker);
      });
      mapRef.current.leafletElement.addLayer(markers);
      markers.on('click', function (e) {
        var threat = e.propagatedFrom.options.threat;
        if (threat) {
          if (loggedIn) {
            dispatch(map.actions.setSelectedThreat(threat));
            dispatch(map.actions.setIsThreatDialogOpen(true));
          } else {
            var popupContent = getPopup(e.propagatedFrom.options.threat);
            e.propagatedFrom.bindPopup(popupContent).openPopup();
          }
        }
      });
    }
  }, [dispatch, loggedIn, threats]);

  useEffect(() => {
    if (mapRef.current.leafletElement && loggedIn) {
      L.Control.geocoder({
        collapsed: false,
        geocoder: L.Control.Geocoder.nominatim(),
        placeholder: i18n.t('Search'),
        errorMessage: i18n.t('Empty'),
      }).addTo(mapRef.current.leafletElement);
      L.control.scale({ imperial: false, position: 'bottomright' }).addTo(mapRef.current.leafletElement);
      L.control.attribution({ position: 'bottomleft' }).addTo(mapRef.current.leafletElement);

      const options = {
        position: 'topright',
        draw: {
          polyline: false,
          polygon: false,
          circle: false,
          rectangle: false,
          marker: false,
          circlemarker: {
            color: '#0f5249',
          },
        },
      };
      L.drawLocal.draw.toolbar.actions.title = i18n.t('CancelToolTip');
      L.drawLocal.draw.toolbar.actions.text = i18n.t('Cancel');
      L.drawLocal.draw.toolbar.buttons.circlemarker = i18n.t('AddNewThreat');
      L.drawLocal.draw.handlers.circlemarker.tooltip.start = i18n.t('ClickToAddNewThreat');
      const drawControl = new L.Control.Draw(options);
      mapRef.current.leafletElement.addControl(drawControl);

      mapRef.current.leafletElement.on(L.Draw.Event.CREATED, (e) => {
        dispatch(
          map.actions.setSelectedThreat({
            id: null,
            latitude: e.layer._latlng.lat,
            longitude: e.layer._latlng.lng,
            readOnly: false,
          }),
        );
        dispatch(map.actions.setIsThreatDialogOpen(true));
      });
    }
  }, [dispatch, loggedIn]);

  return (
    <div className={classes.container}>
      <ThreatDialog></ThreatDialog>
      <ValidationFrom></ValidationFrom>
      <div className={isOpen ? classes.list : classes.listHidden}>
        <Sidebar />
      </div>
      <div className={classes.map}>
        <LeafLetMap
          className={classes.map}
          center={position}
          zoom={5}
          zoomControl={false}
          animate={true}
          ref={mapRef}
          maxZoom={18}
        >
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="World imagery">
              <TileLayer
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <Overlay name="Labels">
              <TileLayer
                maxZoom={18}
                attribution="Carto © CC BY 3.0, OpenStreetMap © ODbL"
                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_only_labels/{z}/{x}/{y}.png"
              />
            </Overlay>
            <Overlay name="Natura 2000">
              <WMSTileLayer
                maxZoom={18}
                minZoom={5}
                opacity={0.5}
                name="Natura 2000 2"
                transparent="true"
                layers="naturaalert:natura2000"
                attribution="Geopedia.world"
                format="image/png"
                url="https://map.geo-wiki.org/geoserver/naturaalert/wms?"
              />
            </Overlay>
            <Overlay name="IBA">
              <WMSTileLayer
                maxZoom={18}
                minZoom={5}
                opacity={0.5}
                name="IBA 2"
                transparent="true"
                layers="naturaalert:iba"
                attribution="Birdlife.org"
                format="image/png"
                url="https://map.geo-wiki.org/geoserver/naturaalert/wms?"
              />
            </Overlay>
          </LayersControl>
          <ZoomControl position="bottomright" />
          <Control position="topleft">
            <Fab onClick={() => updateOpen()} color="primary" aria-label="add">
              <MenuOpenIcon />
            </Fab>
          </Control>
          <Control position="topleft">
            <Fab onClick={() => refreshMap()} color="primary" aria-label="add">
              <RefreshIcon />
            </Fab>
          </Control>
        </LeafLetMap>
        <SnackBar />
      </div>
    </div>
  );
}

export default Map;
