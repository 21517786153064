import { createAction } from '@reduxjs/toolkit';

const setSelectedIBA = createAction('careTakers/setSelectedIBA');
const setUserList = createAction('careTakers/setUserList');
const setIbaList = createAction('careTakers/setIbaList');

const setIsUsersDialogOpen = createAction('careTakers/setIsUserDialogOpen');

export default {
  setSelectedIBA,
  setUserList,
  setIsUsersDialogOpen,
  setIbaList,
};
