import { createAction } from '@reduxjs/toolkit';

const fetchUserAsync = createAction('user/fetchUserAsync');
const setUser = createAction('user/setUser');
const resetUser = createAction('user/resetUser');
const logout = createAction('user/logout');
const loginAsync = createAction('user/login');

export default {
  fetchUserAsync,
  setUser,
  resetUser: resetUser,
  loginAsync,
  logout,
};
