import React from 'react';
import i18n from 'i18next';
import MaterialTable from 'material-table';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import annualAssessment from './../../../redux/actions/annualAssessment';
import { HABITAT_TYPE, OCCUPANCY_PERCENTAGE, HABITAT_QUALITY } from '../../../constants';
export default function ScreenThree() {
  var habitatList = useSelector((state) =>
    state?.annualAssessment?.form?.habitatList.map((o) => ({ ...o, tableData: {} })),
  );
  var dispatch = useDispatch();
  return (
    <div>
      <MaterialTable
        title=""
        columns={[
          {
            title: i18n.t('Major_habitat_type'),
            field: 'habitatType',
            render: (rowData) => i18n.t(`${rowData.habitatType}`),
            editComponent: (props) => (
              <Autocomplete
                options={HABITAT_TYPE}
                value={HABITAT_TYPE.find((x) => x.value === props.value)}
                getOptionLabel={(option) => i18n.t(`${option.value}`)}
                onChange={(_event, newValue) => {
                  props.onChange(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={i18n.t('Major_habitat_type')} variant="outlined" />
                )}
              />
            ),
          },
          {
            title: i18n.t('Current_%_Remaining'),
            field: 'currentBirdsRemaining',
            render: (rowData) => i18n.t(`${rowData.currentBirdsRemaining}`),
            editComponent: (props) => (
              <Autocomplete
                options={OCCUPANCY_PERCENTAGE}
                value={OCCUPANCY_PERCENTAGE.find((x) => x.value === props.value)}
                getOptionLabel={(option) => i18n.t(`${option.value}`)}
                onChange={(_event, newValue) => {
                  props.onChange(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={i18n.t('Current_%_Remaining')} variant="outlined" />
                )}
              />
            ),
          },
          {
            title: i18n.t('Current_habitat_quality'),
            field: 'currentHabitatQuality',
            render: (rowData) => i18n.t(`${rowData.currentHabitatQuality}`),
            editComponent: (props) => (
              <Autocomplete
                options={HABITAT_QUALITY}
                value={HABITAT_QUALITY.find((x) => x.value === props.value)}
                getOptionLabel={(option) => i18n.t(`${option.value}`)}
                onChange={(_event, newValue) => {
                  props.onChange(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={i18n.t('Current_habitat_quality')} variant="outlined" />
                )}
              />
            ),
          },
          {
            title: i18n.t('Comments'),
            field: 'comments',
          },
        ]}
        data={habitatList}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, _reject) => {
              newData.id = uuidv4();
              dispatch(annualAssessment.addHabitatList(newData));
              resolve();
            }),
          onRowUpdate: (newData, _oldData) =>
            new Promise((resolve, _reject) => {
              dispatch(annualAssessment.updateHabitatList(newData));
              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, _reject) => {
              dispatch(annualAssessment.removeHabitatList(oldData));
              resolve();
            }),
        }}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
}
