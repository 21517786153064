import { getWithExpiry, setWithExpiry } from './../../utils/commonUtils';
import { createReducer } from '@reduxjs/toolkit';
import iba from '../actions/iba';
import * as _ from 'lodash';
let localIbas = JSON.parse(getWithExpiry('ibas'));
const initialState = localIbas ? localIbas : [];

export default createReducer(initialState, (builder) =>
  builder.addCase(iba.setIba, (state, action) => {
    const ibas = action.payload;
    var sorted = _.sortBy(ibas, ['countryName', 'code']);
    if (sorted) setWithExpiry('ibas', JSON.stringify(sorted), 1440);
    return [...sorted];
  }),
);
