import React, { useEffect, useState } from 'react';
import { Collapse } from '@material-ui/core';
import './LandingPage.scss';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   iconButton: {
//     fontSize: 40,
//     color: 'white',
//     padding: '20px',
//   },
//   fab: {
//     marginBottom: '20px',
//   },
// }));
const LandingPage = () => {
  // const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <div className="landing-page">
      <div className="hero">
        <div className="landing-logo">
          <a href="/">
            <img className="brand" src="/images/logo-white.png" alt="logo" width="150" />
          </a>
          <div className="menu-spacer"></div>
          <div className="landing-menu">
            <div className="top-link-container">
              <a href="/about" className="top-link">
                About Us
              </a>
            </div>
          </div>
        </div>
        <div className="landing-container">
          <div className="welcome-text">
            <Collapse
              in={checked}
              style={{ transformOrigin: '0 0 0' }}
              {...(checked ? { timeout: 1000 } : {})}
            >
              <p>
                Welcome to <br /> Natura Alert
              </p>
              <p className="join-text">Join the Citizen Science movement!</p>
              <div className="links">
                <a href="/explore" className="link">
                  <img src="/images/web-site.png" alt="web" />
                  <span>Website</span>
                </a>

                <a
                  href="https://apps.apple.com/us/app/natura-alert/id1437998439?mt=8"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/apple-icon.png" alt="apple" />
                  <span>App Store</span>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=at.ac.iiasa.naturaalert"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/google-play-icon.png" alt="google" />
                  <span>Play Store</span>
                </a>
              </div>
              <div className="discord">
                <a
                  href="https://discord.gg/XNFAfNJ5BQ"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/discord.svg" alt="google" />
                  <span>Join Community</span>
                </a>
              </div>
            </Collapse>
          </div>

          {/* <Fab color="secondary" aria-label="" className={classes.fab}>
            <ExpandMoreIcon className={classes.iconButton} />
          </Fab> */}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
