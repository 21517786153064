import React from 'react';
import { Box, Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

function BigCard(props) {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.root}>
        <CardHeader title={props.title} />
        <Divider />
        <CardContent>
          <Box position="relative">{props.children}</Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default BigCard;
