import { createReducer } from '@reduxjs/toolkit';
import careTakers from '../actions/careTakers';

const initialState = {
  selectedIba: null,
  userList: [],
  isUsersDialogOpen: false,
  ibaList: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(careTakers.setSelectedIBA, (state, action) => {
      state.selectedIba = action.payload;
    })
    .addCase(careTakers.setUserList, (state, action) => {
      state.userList = action.payload;
    })
    .addCase(careTakers.setIbaList, (state, action) => {
      state.ibaList = action.payload;
    })
    .addCase(careTakers.setIsUsersDialogOpen, (state, action) => {
      state.isUsersDialogOpen = action.payload;
    }),
);
