const { createSlice } = require('@reduxjs/toolkit');

const sortThreat = (a, b) => {
  return a.id - b.id;
};

const map = createSlice({
  name: 'map',
  initialState: {
    threats: [],
    isOpen: false,
    selectedThreat: null,
    activeTab: 0,
    isThreatDialogOpen: false,
    isThreatValidationDialogOpen: false,
  },
  reducers: {
    setThreat(state, action) {
      state.threats = action.payload;
    },
    resetThreats(state, _action) {
      state.threats = [];
    },
    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    setSelectedThreat(state, action) {
      state.selectedThreat = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setIsThreatDialogOpen(state, action) {
      state.isThreatDialogOpen = action.payload;
    },
    setIsThreatValidationDialogOpen(state, action) {
      state.isThreatValidationDialogOpen = action.payload;
    },
    setUpdateThreat(state, action) {
      state.threats = [...state.threats.filter((t) => t.id !== action.payload.id), action.payload].sort(
        sortThreat,
      );
    },
    setCreateThreat(state, action) {
      state.threats = [...state.threats, action.payload].sort(sortThreat);
    },
    setDeleteThreat(state, action) {
      state.threats = [...state.threats.filter((t) => t.id !== action.payload)].sort(sortThreat);
    },
  },
});

export default map;
