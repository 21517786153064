import React, { useState } from 'react';
import ThreatForm from './ThreatForm';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CommentIcon from '@material-ui/icons/Comment';
import DeleteIcon from '@material-ui/icons/Delete';
import Save from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import i18n from './../../i18n';
import { usePermission } from './../../hooks/usePermission';
import { naturaAlertApi } from './../../api/index';
import { useLocation } from './../../hooks/useLocation';
import { prepareThreat } from './../../utils/threatUtil';
import { TIMING, SEVERITY, SCOPE, THREAT_TYPE, THREAT_SUBTYPE } from '../../constants';
import { computeImpact } from './../../utils/threatUtil';
import swal from '@sweetalert/with-react';
import map from '../../redux/slices/map';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#fff',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton color="secondary" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ThreatDialog() {
  const defaultThreat = {
    timing: null,
    type: null,
    subType: null,
    severity: null,
    scope: null,
    comment: '',
    loading: false,
    saving: false,
    isInsideIBA: false,
    error: '',
    latitude: null,
    longitude: null,
    location: null,
    userLocation: null,
    id: null,
    fileUrls: [],
    impact: i18n.t('Unknown'),
  };
  var dispatch = useDispatch();
  const handleClose = () => {
    dispatch(map.actions.setIsThreatDialogOpen(false));
  };
  var location = useLocation();
  var selectedThreat = useSelector((state) => state?.map?.selectedThreat);
  var isOld = selectedThreat !== null && selectedThreat.id !== null;
  var isAdmin = useSelector((state) => state?.user?.isAdmin);
  var userId = useSelector((state) => state?.user?.sub);
  var hasDeletePermission = usePermission('NaturaAlert.Threat.Delete');
  var hasUpdatePermission = usePermission('NaturaAlert.Threat.Update');
  var hasCreatePermission = usePermission('NaturaAlert.Threat.Create');
  var hasVerifyPermission = usePermission('NaturaAlert.Threat.Verify');

  const [canSave, setCanSave] = useState(hasCreatePermission);
  const [canDelete, setCanDelete] = useState(hasDeletePermission);
  const [canVerify, setCanVerify] = useState(hasVerifyPermission);

  const [isReadOnly, setIsReadOnly] = useState(false);

  const isThreatDialogOpen = useSelector((state) => state?.map?.isThreatDialogOpen);
  const [threat, setThreat] = useState(defaultThreat);
  const openValidationDialog = () => {
    dispatch(map.actions.setIsThreatValidationDialogOpen(true));
  };

  const saveThreat = () => {
    setThreat((threat) => ({ ...threat, saving: true, error: '' }));
    const payload = prepareThreat(threat, selectedThreat, location);
    if (threat.id) {
      naturaAlertApi
        .updateThreat(threat.id, payload)
        .then((threat) => {
          update(threat);
          setThreat((threat) => ({ ...threat, saving: false }));
        })
        .catch((err) => {
          setThreat((threat) => ({ ...threat, saving: false, error: err.message }));
        });
    } else {
      naturaAlertApi
        .insertThreat(payload)
        .then((data) => {
          setThreat((threat) => ({
            ...threat,
            saving: false,
            id: data.id,
            latitude: data.latitude,
            longitude: data.longitude,
            location: data.location,
            userLocation: data.userLocation,
          }));
          add(data);
          isOld = false;
        })
        .catch((err) => {
          setThreat((threat) => ({ ...threat, saving: false, error: err.message }));
        });
    }
  };

  const update = (threat) => {
    dispatch(map.actions.setUpdateThreat(threat));
  };
  const add = (threat) => {
    dispatch(map.actions.setCreateThreat(threat));
    dispatch(map.actions.setSelectedThreat(threat));
  };

  const updateImpact = () => {
    try {
      var status = i18n.t('Low');
      status = computeImpact(threat.scope.value, threat.timing.value, threat.severity.value);
      setThreat((threat) => ({
        ...threat,
        impact: status,
      }));
    } catch {}
  };

  const handleSave = () => {
    //check if all mandatory fields are filled
    if (threat.isInsideIBA) {
      if (threat.type && threat.subType && threat.timing && threat.severity && threat.scope) {
        saveThreat();
      } else {
        setThreat((threat) => ({
          ...threat,
          error: i18n.t('PleaseFillInAllRequiredFields'),
        }));
      }
    } else {
      if (threat.type && threat.subType && threat.severity) {
        saveThreat();
      } else {
        setThreat((threat) => ({
          ...threat,
          error: i18n.t('PleaseFillInAllRequiredFields'),
        }));
      }
    }
  };

  const deleteThreat = () => {
    swal({
      title: i18n.t('AreYouSure'),
      text: 'Once deleted, you will not be able to recover this threat!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(map.actions.deleteThreatAsync(threat.id));
        dispatch(map.actions.setIsThreatDialogOpen(false));
      }
    });
  };

  const resetState = () => {
    setCanSave(false);
    setCanDelete(false);
    setCanVerify(false);
    setIsReadOnly(false);
    setThreat(defaultThreat);
  };

  const onEnter = () => {
    resetState();

    if (selectedThreat && selectedThreat.id) {
      setThreat((threat) => ({ ...threat, loading: true }));
      naturaAlertApi
        .getThreat(selectedThreat.id)
        .then((response) => {
          setThreat({
            timing: TIMING.find((t) => t.value === response.timing),
            type: THREAT_TYPE.find((t) => t.value === response.typeCode),
            subType: THREAT_SUBTYPE.find((s) => s.value === response.subTypeCode),
            severity: SEVERITY.find((s) => s.value === response.severity),
            scope: SCOPE.find((s) => s.value === response.scope),
            comment: response.comment || '',
            id: response.id,
            latitude: response.latitude,
            longitude: response.longitude,
            username: response.username,
            loading: false,
            saving: false,
            fileUrls: response.files,
            isInsideIBA: response.ibaId ? true : false,
            validationMessage: response.validationMessage,
            location: response.location,
            userLocation: response.userLocation,
            isValid: response.isValid,
          });

          if (isAdmin) {
            setCanSave(true);
            setCanDelete(true);
            setCanVerify(true);
          } else {
            if (response?.creatorId === userId && hasUpdatePermission) {
              setCanSave(true);
              setCanDelete(true);
              setIsReadOnly(false);
            } else {
              setIsReadOnly(true);
            }
          }
          updateImpact();
        })
        .catch((err) => {
          setThreat((threat) => ({ ...threat, loading: false, error: err.message }));
        });
    } else {
      if (selectedThreat && selectedThreat.latitude && selectedThreat.longitude) {
        setThreat((threat) => ({
          ...threat,
          loading: true,
          latitude: selectedThreat.latitude,
          longitude: selectedThreat.longitude,
        }));
        resetState();
        naturaAlertApi
          .getIBAFiltered({ latLng: selectedThreat.latitude + ',' + selectedThreat.longitude })
          .then((ibas) => {
            setThreat((threat) => ({ ...threat, loading: false }));
            if (ibas.length) {
              setThreat((threat) => ({ ...threat, isInsideIBA: true }));
            }
            setCanDelete(false);
            setCanVerify(false);
            if (hasCreatePermission) {
              setCanSave(true);
            }
            updateImpact();
          })
          .catch((err) => {
            setThreat((threat) => ({ ...threat, loading: false, error: err.message }));
          });
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        style={{
          zIndex: '1000',
        }}
        open={isThreatDialogOpen}
        onEnter={onEnter}
        onClose={() => handleClose()}
      >
        <DialogTitle id="form-dialog-title" onClose={handleClose}>
          {isOld ? (isReadOnly ? i18n.t('ThreatDetails') : i18n.t('EditThreat')) : i18n.t('AddNewThreat')}
        </DialogTitle>
        <DialogContent>
          <ThreatForm
            threat={threat}
            setThreat={setThreat}
            isReadOnly={isReadOnly}
            canCreateAndEdit={canSave}
          ></ThreatForm>
        </DialogContent>
        <DialogActions>
          {hasDeletePermission && canDelete && (
            <div className="delete">
              <Button
                variant="contained"
                color="secondary"
                className="p-4"
                startIcon={<DeleteIcon />}
                onClick={() => deleteThreat()}
              >
                {i18n.t('Delete')}
              </Button>
            </div>
          )}
          {hasVerifyPermission && canVerify && (
            <div className="validate">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => openValidationDialog()}
                startIcon={<CommentIcon fontSize="large" variant="contained" />}
              >
                {i18n.t('validate')}
              </Button>
            </div>
          )}
          {hasCreatePermission && canSave && (
            <div className="save">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                startIcon={<Save />}
                disabled={threat.saving || isReadOnly}
              >
                {threat.saving ? <i className="fa fa-spinner fa-spin fa-fw" /> : i18n.t('Save')}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
