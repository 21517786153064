import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import createSagaMiddleware, { END } from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import root from './sagas';
const composeEnhancers = compose;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const initialState = {};
export const store = createStore(rootReducer, initialState, enhancer);

store.runSaga = sagaMiddleware
  .run(root)
  .toPromise()
  .catch((e) => {
    console.error({ message: e.message, source: 'sagaError', stacktrace: e.sagaStack });
  });
store.close = () => store.dispatch(END);
