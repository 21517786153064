import * as _ from 'lodash';

export const emailValidation = (emailStr) => {
  var re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return re.test(emailStr);
};

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  ttl = ttl * 60 * 1000;
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

class Cookie {
  findAll = () => {
    const cookies = {};
    const cookieList = document.cookie.split(';');
    cookieList.forEach(function (c) {
      let arr = c.split('='),
        key = arr[0],
        value = null;
      let size = _.size(arr);
      if (size > 1) {
        value = arr.slice(1).join('');
      }
      cookies[key] = value;
    });
    return cookies;
  };

  find = (name) => {
    let cookie = null,
      list = this.findAll();
    _.each(list, function (value, key) {
      if (key === name) cookie = value;
    });
    return cookie;
  };

  create = (name, value, time) => {
    let today = new Date(),
      offset = typeof time == 'undefined' ? 1000 * 60 * 60 * 24 : time * 1000,
      expires_at = new Date(today.getTime() + offset);

    let cookie = _.map(
      {
        name: escape(value),
        expires: expires_at.toGMTString(),
        path: '/',
      },
      function (value, key) {
        return [key === 'name' ? name : key, value].join('=');
      },
    ).join(';');

    document.cookie = cookie;
    return this;
  };

  destroy = (name, cookie) => {
    if (cookie === this.find(name)) {
      this.create(name, null, -1000000);
    }
    return this;
  };
}

export const cookie = new Cookie();
