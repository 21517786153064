import React from 'react';
import IBAList from './../components/IBA/IBAList';
import './IBAPage.scss';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CareTakerList from './../components/IBA/CareTakerList';
import UserDialog from '../components/User/UserDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function IBAPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs>
          <IBAList></IBAList>
        </Grid>
        <Grid item xs>
          <CareTakerList />
        </Grid>
      </Grid>
      <UserDialog />
    </div>
  );
}
