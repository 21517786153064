import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import AnnualAssessmentDialog from './AnnualAssessmentDialog';
import i18n from 'i18next';
import { naturaAlertApi } from './../../api/index';
import { format, parseISO } from 'date-fns';
import swal from '@sweetalert/with-react';
import { useDispatch } from 'react-redux';
import annualAssessment from '../../redux/actions/annualAssessment';
import { usePermission } from './../../hooks/usePermission';
const useStyles = makeStyles((_theme) => ({
  root: {
    padding: '10px',
  },
}));
export default function AnnualAssessmentList() {
  const classes = useStyles();
  var dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [assessment, setAssessment] = useState([]);
  var hasAnnulAssessmentPermission = usePermission('NaturaAlert.AnnulAssessment');
  var hasAnnulAssessmentCreatePermission = usePermission('NaturaAlert.AnnulAssessment.Create');
  var hasAnnulAssessmentDeletePermission = usePermission('NaturaAlert.AnnulAssessment.Delete');
  var hasAnnulAssessmentUpdatePermission = usePermission('NaturaAlert.AnnulAssessment.Update');

  const getAllAnnualAssessment = () => {
    try {
      naturaAlertApi.getAllAnnualAssessment(true).then((res) => setAssessment(res));
    } catch (error) {
      console.error(error);
    }
  };

  const updateAnnualAssessment = (rowData) => {
    try {
      naturaAlertApi.getAnnualAssessment(rowData.id).then((res) => {
        const formState = {
          id: res.id,
          name: res.careTakerName,
          email: res.email,
          year: {
            code: res.yearOfAssessment,
          },
          iba: res.iba,
          threats: res.pressureThreatList,
          ibaSpeciesList: res.stateJudgementDataList,
          habitatList: res.habitatDataList,
          siteLegalProtection: res.legallyProtectedAreaInfo,
          managmentPlanInfo: res.managmentPlanInfo,
          conservationActivity: res.conservationActivity,
          protectionOrganizationAnswer: res.protectionOrganizationAnswer,
          relationshipBetweenSeo: res.relationshipBetweenSeo,
          activitiesDoneByOrg: res.activitiesDoneByOrg,
          qualityOfAssessment: res.qualityOfAssessment,
        };
        dispatch(annualAssessment.setFrom(formState));
        setOpen(true);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const downloadReport = (selectedData) => {
    var guids = [];
    selectedData.forEach((data) => {
      guids.push(data.id);
    });
    var payload = {
      guids: guids,
    };
    naturaAlertApi.getAnnualAssessmentReportFiltered(payload).then((data) => {
      const file = new Blob([data], { type: 'application/vnd.ms-excel' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = 'AssessmentReport-' + new Date().toISOString() + '.xlsx';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  };

  useEffect(() => getAllAnnualAssessment(), [open]);
  return (
    <div className={classes.root}>
      {hasAnnulAssessmentPermission && (
        <MaterialTable
          title="Annual Assessment"
          columns={[
            { title: i18n.t('Name'), field: 'careTakerName' },
            { title: i18n.t('yearOfAssessment'), field: 'yearOfAssessment' },
            { title: i18n.t('countryName'), field: 'countryName' },
            { title: i18n.t('ibaCode'), field: 'ibaCode' },
            {
              title: i18n.t('created'),
              field: 'created',
              render: (rowData) => `${format(parseISO(rowData.created), 'dd/MM/yyyy')}`,
            },
          ]}
          data={assessment}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            pageSizeOptions: [5, 10, 15, 20],
            selection: true,
            filtering: true,
          }}
          actions={[
            {
              disabled: !hasAnnulAssessmentCreatePermission,
              icon: 'add',
              tooltip: 'Create Assessment',
              isFreeAction: true,
              onClick: (_event) => {
                dispatch(annualAssessment.reset());
                setOpen(true);
              },
            },
            {
              disabled: !hasAnnulAssessmentCreatePermission,
              icon: 'save_alt',
              tooltip: 'Export Assessment',
              onClick: (_event, rowData) => {
                downloadReport(rowData);
              },
            },
            {
              disabled: !hasAnnulAssessmentUpdatePermission,
              icon: 'edit',
              position: 'row',
              tooltip: 'Edit Assessment',
              onClick: (_event, rowData) => {
                updateAnnualAssessment(rowData);
              },
            },
            {
              disabled: !hasAnnulAssessmentDeletePermission,
              icon: 'delete',
              position: 'row',
              tooltip: 'Delete Assessment',
              onClick: (_event, rowData) => {
                swal({
                  title: i18n.t('AreYouSure'),
                  icon: 'warning',
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    naturaAlertApi.deleteAnnualAssessment(rowData.id).then((res) => {
                      if (res) {
                        getAllAnnualAssessment();
                        swal({
                          title: 'Delete Success!',
                          icon: 'success',
                        });
                      } else {
                        swal({
                          title: 'Delete failed!',
                          icon: 'error',
                        });
                      }
                    });
                  }
                });
              },
            },
          ]}
        />
      )}
      <AnnualAssessmentDialog open={open} setOpen={setOpen} />
    </div>
  );
}
