import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import user from '../../redux/actions/user';
const LoginForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(user.loginAsync());
  }, [dispatch]);
  return <></>;
};

export default LoginForm;
