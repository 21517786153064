import React from 'react';
import AnnualAssessmentList from '../components/AnnualAssessment/AnnualAssessmentList';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function AnnualAssessmentPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs>
          <AnnualAssessmentList />
        </Grid>
      </Grid>
    </div>
  );
}
