import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import Grid from '@material-ui/core/Grid';
import { naturaAlertApi } from '../../api';
import {
  Button,
  Card,
  Container,
  CardActions,
  CardHeader,
  CardContent,
  Box,
  Divider,
  Typography,
  Avatar,
  makeStyles,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    height: 100,
    width: 100,
  },
}));

export default function Profile(props) {
  var isAdmin = useSelector((state) => state?.user?.isAdmin);
  var email = useSelector((state) => state?.user?.email);
  const [isDownloadBusy, setDownloadIsBusy] = useState(false);

  const [picUrl, setPicUrl] = useState('');
  const [username, setUsername] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [myThreatCount, setMyThreatCount] = useState('');

  useEffect(() => {
    async function fetchData() {
      const response = await naturaAlertApi.getProfileInfo();
      setPicUrl(response.pictureUrl);
      setUsername(response.username);
      setMyThreatCount(response.myThreats);
      setTotalCount(response.totalThreats);
    }
    fetchData();
  }, []);

  const classes = useStyles();

  const downloadExcelFile = () => {
    setDownloadIsBusy(true);
    const element = document.createElement('a');
    naturaAlertApi
      .downloadExcelExport()
      .then((data) => {
        const file = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'ThreatExport-' + new Date().toString() + '.xlsx';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      })
      .catch(() => setDownloadIsBusy(false))
      .then(() => setDownloadIsBusy(false));
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Box alignItems="center" display="flex" flexDirection="column">
                  <Avatar className={classes.avatar} src={picUrl} />
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    {username}
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button color="primary" fullWidth variant="text">
                  Upload picture
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Card raised={true}>
              <CardHeader title="Profile" subheader="" />
              <CardContent>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs>
                    {i18n.t('Email')}:
                  </Grid>
                  <Grid item xs>
                    {email}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs>
                    {i18n.t('YourReports')}:
                  </Grid>
                  <Grid item xs>
                    {myThreatCount}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs>
                    {i18n.t('TotalCommunityReports')}:
                  </Grid>
                  <Grid item xs>
                    {totalCount}
                  </Grid>
                </Grid>
                <Divider />
                {isAdmin ? (
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs>
                      {i18n.t('Download')}:
                    </Grid>
                    <Grid item xs>
                      <Button variant="contained" color="secondary" onClick={downloadExcelFile}>
                        {isDownloadBusy ? <i className="fa fa-spinner fa-spin fa-fw" /> : ''}{' '}
                        {i18n.t('Download')} Excel
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
                <Divider />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
