import { naturaAlertApi } from '../../api/index';
import loading from '../actions/loading';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import threat from '../actions/threat';
import alert from '../actions/alert';
import map from '../slices/map';
import { processThreats } from '../../utils/threatUtil';

function* fetchUserThreat() {
  yield put(loading.start({ key: 'fetchUserThreat', opacity: 1 }));
  const data = yield call(naturaAlertApi.getUserThreats);
  const finalData = processThreats(data);
  yield put(threat.setUserThreats(finalData));
  yield put(loading.stop({ key: 'fetchUserThreat' }));
}

function* fetchThreat() {
  yield put(loading.start({ key: 'fetchThreat', opacity: 1 }));
  const data = yield call(naturaAlertApi.getThreats);
  yield put(map.actions.setThreat(data));
  yield put(loading.stop({ key: 'fetchThreat' }));
}

function* searchThreat(payload) {
  yield put(loading.start({ key: 'fetchThreat', opacity: 1 }));
  const data = yield call(naturaAlertApi.searchThreat, payload);
  yield put(threat.setThreatSearch(data));
  yield put(loading.stop({ key: 'fetchThreat' }));
}

function* deleteThreat(data) {
  yield put(loading.start({ key: 'deleteThreat', opacity: 1 }));
  try {
    yield call(naturaAlertApi.deleteThreat, data.payload);
    yield put(threat.setDeleteThreat(data.payload));
    yield put(alert.success({ key: 'deleteThreat', message: 'Threat deleted' }));
  } catch {
    yield put(alert.error({ key: 'deleteThreat', message: 'Threat deleted failed' }));
  }
  yield put(loading.stop({ key: 'deleteThreat' }));
}

function* verifyThreat(data) {
  yield put(loading.start({ key: 'verifyThreat', opacity: 1 }));
  var result = yield call(naturaAlertApi.verifyThreat, data.payload);
  if (result) {
    yield put(alert.success({ key: 'verifyThreat', message: 'Verification success' }));
  } else {
    yield put(alert.error({ key: 'verifyThreat', message: 'failed to verify threat' }));
  }
  yield put(loading.stop({ key: 'verifyThreat' }));
}

export default function* () {
  yield all([
    takeLatest(threat.fetchUserThreatAsync.type, fetchUserThreat),
    takeLatest(threat.fetchThreatAsync.type, fetchThreat),
    takeLatest(threat.fetchThreatSearchAsync.type, searchThreat),
    takeLatest(threat.deleteThreatAsync.type, deleteThreat),
    takeLatest(threat.verifyThreatAsync.type, verifyThreat),
  ]);
}
