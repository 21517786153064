import { createReducer } from '@reduxjs/toolkit';
import AppActions from '../actions/appConfig';

const initialState = {};

export default createReducer(initialState, (builder) =>
  builder.addCase(AppActions.setAppConfig, (state, action) => {
    return action.payload;
  }),
);
