import { createMuiTheme } from '@material-ui/core/styles';
import { colorScheme } from './colorScheme';
export const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      // '@global': {
      //   '*': {
      //     'scrollbar-width': 'thin',
      //   },
      //   '*::-webkit-scrollbar': {
      //     width: '4px',
      //     height: '4px',
      //     color: colorScheme.primary.main,
      //   },
      // },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: colorScheme.primary.main,
        color: '#fff',
      },
    },
    MuiDialogActions: {
      root: {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: colorScheme.borderColor,
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: colorScheme.primary.main,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#e7c45b',
      },
    },
  },
  palette: {
    primary: {
      light: '#41877f',
      main: '#126a5f',
      dark: '#0c4a42',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e7c45b',
      main: '#e1b633',
      dark: '#9d7f23',
      contrastText: '#000',
    },
  },
  typography: {
    useNextVariants: true,
  },
});
