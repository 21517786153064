import {
  COVERAGETYPE,
  CONSERVATION_ACTIVITY_TYPE,
  MANAGMENT_PLAN_TYPE,
  IBA_CONSERVATION,
  IMPACT,
  STATE_CONDITION,
  THREAT_TYPE,
  THREAT_SUBTYPE,
} from './../constants/index';
import i18n from 'i18next';
import { format } from 'date-fns';
export function prepareThreat(threat, selectedThreat, location) {
  const payload = {
    comment: '',
    fileUrls: [],
    location: {
      accuracy: 0,
      altitude: 0,
      altitudeAccuracy: 0,
      heading: 0,
      latitude: 0,
      longitude: 0,
      speed: 0,
      timestamp: new Date().toISOString(),
      id: '',
    },
    scope: 0,
    severity: 0,
    subTypeCode: '',
    timing: 0,
    typeCode: '',
    device: 0,
    userLocation: {
      accuracy: 0,
      altitude: 0,
      altitudeAccuracy: 0,
      heading: 0,
      latitude: 0,
      longitude: 0,
      speed: 0,
      timestamp: new Date().toISOString(),
      id: '',
    },
  };

  payload['typeCode'] = threat.type.value;
  payload['subTypeCode'] = threat.subType.value;
  payload['severity'] = threat.severity.value;
  payload['comment'] = threat.comment;
  payload['fileUrls'] = threat.fileUrls;
  if (threat.timing) payload['timing'] = threat.timing.value;
  if (threat.scope) payload['scope'] = threat.scope.value;
  //new threats get location from props
  payload.location = {
    latitude: threat.latitude ? threat.latitude : selectedThreat.latitude,
    longitude: threat.longitude ? threat.longitude : selectedThreat.longitude,
    timestamp: new Date().toISOString(),
    id: threat?.location?.id ? threat?.location?.id : '00000000-0000-0000-0000-000000000000',
  };

  payload.userLocation = {
    latitude: location.latitude ? location.latitude : selectedThreat.latitude,
    longitude: location.longitude ? location.longitude : selectedThreat.longitude,
    timestamp: new Date().toISOString(),
    id: threat?.userLocation?.id ? threat?.userLocation?.id : '00000000-0000-0000-0000-000000000000',
  };
  return payload;
}

export const computeImpact = (scope, timing, severity) => {
  try {
    var impact = scope + timing + severity;
    var status = IMPACT[0];
    switch (impact) {
      case 0:
      case 1:
      case 2:
        status = IMPACT[0];
        break;
      case 3:
      case 4:
      case 5:
        status = IMPACT[1];
        break;
      case 6:
      case 7:
        status = IMPACT[2];
        break;
      case 8:
      case 9:
        status = IMPACT[3];
        break;
      default:
        status = IMPACT[0];
        break;
    }
    return status;
  } catch {}
};

export const getThreatStatus = (threats) => {
  const statusVsCount = {};
  IMPACT.map((impact) => {
    var value = impact.label;
    statusVsCount[value] = 0;
    return value;
  });
  statusVsCount['Unknown'] = 0;
  threats.map((threat) => {
    var preValue = statusVsCount[threat.impact];
    statusVsCount[threat.impact] = preValue + 1;
    return preValue;
  });
  for (var i = IMPACT.length - 1; i >= 0; i--) {
    var value = IMPACT[i].label;
    if (statusVsCount[value] > 0) {
      return value;
    }
  }
  return 'Unknown';
};
export const getStateConditionsStatus = (states) => {
  const statusVsCount = {};
  STATE_CONDITION.map((conditionType) => {
    var value = conditionType.label;
    statusVsCount[value] = 0;
    return value;
  });
  states.map((state) => {
    var preValue = statusVsCount[state.condition];
    statusVsCount[state.condition] = preValue + 1;
    return preValue;
  });
  for (var i = 0; i < STATE_CONDITION.length; i++) {
    var value = STATE_CONDITION[i].label;
    if (statusVsCount[value] > 0) {
      return value;
    }
  }
  return STATE_CONDITION[0].label;
};
export const getConservationActionStatus = (siteLegalProtection, managmentPlanInfo, conservationActivity) => {
  if (siteLegalProtection && managmentPlanInfo && conservationActivity) {
    const coverageTypeValue =
      COVERAGETYPE.length - COVERAGETYPE.find((x) => x.value === siteLegalProtection).id;
    const managementValue =
      MANAGMENT_PLAN_TYPE.length - MANAGMENT_PLAN_TYPE.find((x) => x.value === managmentPlanInfo).id;
    const conservationActivityValue =
      CONSERVATION_ACTIVITY_TYPE.length -
      CONSERVATION_ACTIVITY_TYPE.find((x) => x.value === conservationActivity).id;
    const status = coverageTypeValue + managementValue + conservationActivityValue;
    switch (status) {
      case 8:
      case 9:
        return IBA_CONSERVATION[3].label;
      case 6:
      case 7:
        return IBA_CONSERVATION[2].label;
      case 2:
      case 3:
      case 4:
      case 5:
        return IBA_CONSERVATION[1].label;
      case 0:
      case 1:
        return IBA_CONSERVATION[0].label;
      default:
        return IBA_CONSERVATION[0].label;
    }
  }
  return IBA_CONSERVATION[0].label;
};

export const getStatusColor = (status) => {
  const StatusVsColor = [
    { value: 0, color: '#00ff00' },
    { value: 1, color: 'yellow' },
    { value: 2, color: 'orange' },
    { value: 3, color: 'red' },
  ];
  return StatusVsColor.find((x) => x.value === status).color;
};

export const processThreats = (threats) => {
  threats.forEach((threat) => {
    const type = THREAT_TYPE.find((type) => type.value === threat.typeCode);
    const subType = THREAT_SUBTYPE.find((subType) => subType.value === threat.subTypeCode);
    threat.type = i18n.t(type.label);
    threat.subType = i18n.t(subType.label);
    threat.username = threat.username ? threat.username : i18n.t('Anonymous');
  });
  return threats;
};

export const getPopup = (threat) => {
  const subTypeCode = THREAT_SUBTYPE.find((subType) => subType.value === threat.subTypeCode);
  const typeCode = THREAT_TYPE.find((type) => type.value === threat.typeCode);
  var popupContent =
    '<div class="popup-threat"><h1> ' +
    '</h1></div><div class="popup-threat-heading">' +
    i18n.t('type') +
    ':<div class="popup-threat-mainText">' +
    (typeCode ? i18n.t(typeCode.label) : threat.typeCode) +
    '</div></div><div class="popup-threat-heading">' +
    i18n.t('SubThreat') +
    ':<div class="popup-threat-mainText">' +
    (subTypeCode ? i18n.t(subTypeCode.label) : threat.subTypeCode) +
    '</div></div>' +
    '<div class="popup-threat-heading">' +
    i18n.t('created') +
    ':<div class="popup-threat-mainText">' +
    format(new Date(threat.created), 'dd-MM-yyyy HH:mm') +
    '</div></div>' +
    '<div class="popup-threat-login">' +
    i18n.t('login_to_see_more_details') +
    '</div>';
  return popupContent;
};
