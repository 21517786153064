import React, { useEffect, useState } from 'react';
import { naturaAlertApi } from './../../api/index';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
function Top15IBAsInDanger() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const country = useSelector((state) => state.charts.country);
  useEffect(() => {
    (async () => {
      const payload = {};
      setIsLoading(true);
      payload['tenantName'] = country?.name;
      //fetch data from statistics api
      const response = await naturaAlertApi.getIBAsWithThreatCount(payload);
      //add iba code to results, sort and limit to 15
      const result = response
        .map((d) => ({ ...d, id: d.ibaCode }))
        .sort((a, b) => a.count - b.count)
        .reverse()
        .slice(0, 15);
      setData(result);
      setIsLoading(false);
    })();
  }, [country.name]);
  return (
    <div>
      <div className="content">
        {isLoading && data && data.length ? (
          <CircularProgress />
        ) : (
          <table
            style={{
              color: '#126a5f',
              fontSize: '13px',
              textAlign: 'center',
              margin: '1px',
            }}
          >
            <thead style={{ fontSize: '14px', textAlign: 'center' }}>
              <tr>
                <th>
                  <Trans i18nKey="iba_code" />
                </th>
                <th>
                  <Trans i18nKey="iba_name" />
                </th>
                <th>
                  <Trans i18nKey="threats" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((iba) => (
                <tr style={{ textAlign: 'center' }} key={iba.ibaCode}>
                  <td>{iba.ibaCode}</td>
                  <td>{iba.ibaInternationalName}</td>
                  <td>{iba.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Top15IBAsInDanger;
