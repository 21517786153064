import React, { useEffect, useState } from 'react';
import { naturaAlertApi } from './../../api/index';
import { IMPACT } from '../../constants';
import { Pie } from '@nivo/pie';
import i18n from 'i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
export const PieChartColors = {
  0: 'rgb(229, 245, 224)',
  1: 'rgb(161, 217, 155)',
  2: 'rgb(65, 171, 93)',
  3: 'rgb(0, 109, 44)',
};

export const getSliceColor = (data) => PieChartColors[data.id];

const Tooltip = (props) => (
  <div
    style={{
      color: '#126a5f',
      fontSize: '13px',
      textAlign: 'center',
      margin: '1px',
    }}
  >
    {i18n.t(props.label)}: {props.value}
  </div>
);

function OverviewOfIBAs() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const country = useSelector((state) => state.charts.country);
  useEffect(() => {
    (async () => {
      const payload = {};
      setIsLoading(true);
      payload['tenantName'] = country?.name;
      //fetch data from statistics api
      const response = await naturaAlertApi.getImpactCountPerIBA(payload);

      //since data is provided per IBA it needs to be aggregated
      const impactCount = new Array(4).fill(0);
      response.forEach((iba) => {
        iba.impacts.forEach((i) => {
          impactCount[i.impact] = impactCount[i.impact] + i.count;
        });
      });

      //add impact label
      const dataChart3 = impactCount
        .filter((val) => val > 0)
        .map((ic, index) => {
          const impactLabel = IMPACT.find((i) => i.value === index);
          return {
            id: `${index}`,
            value: ic,
            label: impactLabel.label,
          };
        });

      setData(dataChart3);
      setIsLoading(false);
    })();
  }, [country.name]);

  return (
    <div>
      <div className="content">
        {isLoading && data && data.length ? (
          <CircularProgress />
        ) : (
          <div>
            <Pie
              width={500}
              height={300}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
              data={data}
              tooltip={Tooltip}
              colorBy={getSliceColor}
              radialLabel={(d) => i18n.t(d.label)}
              radialLabelsTextColor="#ccc"
              radialLabelsLinkColor="#ccc"
              radialLabelsLinkDiagonalLength={10}
              radialLabelsLinkHorizontalLength={10}
            />
            <div className="pie-chart-caption">{i18n.t('Impact')}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OverviewOfIBAs;
