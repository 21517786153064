import React, { useEffect, useState } from 'react';
import { naturaAlertApi } from './../../api/index';
import { Bar } from '@nivo/bar';
import i18n from 'i18next';
import { THREAT_TYPE } from '../../constants';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
const Tooltip = (props) => (
  <div
    style={{
      color: '#126a5f',
      fontSize: '13px',
      textAlign: 'center',
      margin: '1px',
    }}
  >
    {i18n.t(props.data.label)}: {props.data.count}
  </div>
);

function IBAByMainThreat() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const country = useSelector((state) => state.charts.country);
  useEffect(() => {
    (async () => {
      const payload = {};
      payload['tenantName'] = country?.name;
      setIsLoading(true);
      //fetch data from statistics api
      const response = await naturaAlertApi.getThreatsWithIBACount(payload);
      //add threat type description, sort
      const dataChart2 = response
        .map((d) => {
          const threatType = THREAT_TYPE.find((t) => t.value === d.threatTypeCode);
          return { ...d, label: threatType ? threatType.label : d.threatTypeCode };
        })
        .sort((a, b) => a.threatTypeCode - b.threatTypeCode)
        .reverse();
      setData(dataChart2);
      setIsLoading(false);
    })();
  }, [country.name]);

  return (
    <div>
      <div className="content">
        {isLoading && data && data.length ? (
          <CircularProgress />
        ) : (
          <Bar
            width={500}
            height={300}
            margin={{
              top: 20,
              right: 20,
              bottom: 40,
              left: 60,
            }}
            data={data}
            indexBy="threatTypeCode"
            layout="horizontal"
            keys={['count']}
            labelTextColor="#000000"
            enableGridY={false}
            enableGridX
            axisBottom={{
              legend: i18n.t('NumberOfIBAs'),
              legendPosition: 'middle',
              legendOffset: 30,
            }}
            axisLeft={{
              legend: i18n.t('MainThreatType'),
              legendPosition: 'middle',
              legendOffset: -30,
            }}
            colors="teal"
            colorBy="threatTypeCode"
            padding={0.15}
            tooltip={Tooltip}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: '#888',
                  },
                },
                legend: {
                  text: {
                    fill: '#ccc',
                  },
                },
              },
              grid: {
                line: {
                  strokeDasharray: '2 2',
                  stroke: '#888',
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
}

export default IBAByMainThreat;
