import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import './Validation.scss';
import { naturaAlertApi } from '../../api';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import ThreatList from './ThreatList';
import { processThreats } from '../../utils/threatUtil';
import map from '../../redux/slices/map';
import IbaDropDown from './../IBA/IbaDropDown';

export default function Validation() {
  const [filter, setFilter] = useState('invalid');
  const [isBusy, setIsBusy] = useState(false);
  const [iba, setIba] = useState();
  const [threatList, setThreatList] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    if (iba) {
      setIsBusy(true);
      switch (filter) {
        case 'All':
          naturaAlertApi
            .getThreatsFiltered({ CountryName: iba.countryName, IBACode: iba.code })
            .then((data) => {
              setThreatList(processThreats(data));
              dispatch(map.actions.setThreat(data));
              setIsBusy(false);
            });
          break;
        case 'invalid':
        case 'valid':
          var val = filter === 'valid' ? true : false;
          naturaAlertApi
            .getThreatsFiltered({ CountryName: iba.countryName, IBACode: iba.code, verified: val })
            .then((data) => {
              setThreatList(processThreats(data));
              dispatch(map.actions.setThreat(data));
              setIsBusy(false);
            });
          break;
        default:
        // code block
      }
    } else {
      setThreatList([]);
    }
  }, [dispatch, filter, iba]);

  return (
    <div>
      <div>
        <div className="IbaListWrapper">
          <div className="IbaListHeader">
            <IbaDropDown iba={iba} setIba={setIba} isSearch={false} />
          </div>
          <div className="validation-radio-group">
            <FormControl component="fieldset">
              <Grid item xs={12}>
                <Grid container justify="center">
                  <RadioGroup aria-label="position" value={filter} onChange={handleChange} row>
                    <FormControlLabel
                      value="invalid"
                      control={<Radio />}
                      label={i18n.t('InValid')}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="valid"
                      control={<Radio />}
                      label={i18n.t('Valid')}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="All"
                      control={<Radio />}
                      label={i18n.t('All')}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </FormControl>
          </div>
          <div className={isBusy ? '' : 'hidden-progress'}>
            <LinearProgress color="secondary" />
          </div>
          <div className="threat-list">
            <ThreatList threats={threatList} isValidation={true}></ThreatList>
          </div>
        </div>
      </div>
    </div>
  );
}
