import jwt_dec from 'jwt-decode';
import { createReducer } from '@reduxjs/toolkit';
import user from '../actions/user';
const initialState = {
  accessToken: '',
  loggedIn: false,
  email: '',
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(user.setUser, (state, action) => {
      try {
        const authResponse = action.payload;
        const user = jwt_dec(authResponse.access_token);
        return {
          accessToken: authResponse.access_token,
          loggedIn: authResponse.access_token ? true : false,
          isAdmin: user.role && user.role.length && user.role.includes('admin'),
          isCaretaker: user.role && user.role.length && user.role.includes('caretaker'),
          email: user.email,
          role: user.role,
          name: user.name,
          sub: user.sub,
        };
      } catch {
        return {
          accessToken: '',
          loggedIn: false,
          email: '',
        };
      }
    })
    .addCase(user.resetUser, (state, action) => {
      return {
        accessToken: '',
        loggedIn: false,
        email: '',
        name: '',
      };
    }),
);
