import { useEffect, useState } from 'react';
import { store } from '../redux/store';
import { createGrantedFeatureSelector } from '../redux/selectors/appSelector';

export function useFeature(key) {
  const [feature, setFeature] = useState(false);

  const state = store.getState();
  const policy = createGrantedFeatureSelector(key)(state);
  useEffect(() => {
    setFeature(policy);
  }, [policy]);

  return feature;
}
