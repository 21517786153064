import jwt_dec from 'jwt-decode';
import { loadUserData } from '../utils/loadUserData.js';
import { cookie } from './commonUtils.js';
import { openIdService } from './../services/openId';

export const handleLogin = () => {
  return new Promise((resolve, reject) =>
    openIdService.signIn().then(
      (p) => {
        loadUserData();
        resolve();
      },
      (error) => reject(error),
    ),
  );
};

export const handleLogout = () => {
  cookie.destroy('terms');
  localStorage.clear('terms');
  openIdService.signOut();
};

export const decodeUserFromToken = (token) => {
  const user = jwt_dec(token['id_token']);
  return user;
};

export const getAccessToken = async () => {
  var user = await openIdService.getUser();
  if (user) {
    return user.access_token;
  }
  return null;
};

export const getAuthResponse = async () => {
  var user = await openIdService.getUser();
  if (user) {
    return user;
  }
  return null;
};
