import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import BigCard from './BigCard';
import i18n from 'i18next';
import IBAsInDanger from './IBAsInDanger';
import Top15IBAsInDanger from './Top15IBAsInDanger';
import IBAByMainThreat from './IBAByMainThreat';
import OverviewOfIBAs from './OverviewOfIBAs';
import PressureThreats from './PressureThreats';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './Dashboard.scss';
import { useSelector, useDispatch } from 'react-redux';
import charts from './../../redux/slices/charts';
import { naturaAlertApi } from '../../api';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  country: {
    paddingBottom: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const country = useSelector((state) => state.charts.country);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      var data = await naturaAlertApi.getAllTenant();
      if (active) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Container maxWidth={false} className={classes.root}>
      <div className={classes.country}>
        <Autocomplete
          id="country"
          style={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onChange={(_e, newVal) => {
            if (newVal) {
              dispatch(charts.actions.setCountry(newVal));
            }
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField {...params} label={i18n.t('Select Country')} variant="outlined" />
          )}
        />
      </div>
      <Grid container spacing={3}>
        <Grid item lg={6} md={12} xl={6} xs={12}>
          <BigCard title={`${i18n.t('number_of_ibas_in_danger_in_spain')} ${i18n.t(country.name)}`}>
            <IBAsInDanger />
          </BigCard>
        </Grid>
        <Grid item lg={6} md={12} xl={6} xs={12}>
          <BigCard title={`${i18n.t('top____ibas_in_danger_in_spain')} ${i18n.t(country.name)}`}>
            <Top15IBAsInDanger />
          </BigCard>
        </Grid>
        <Grid item lg={6} md={12} xl={6} xs={12}>
          <BigCard
            title={`${i18n.t('number_of_ibas_by_main_threat_level____in_spain')} ${i18n.t(country.name)}`}
          >
            <IBAByMainThreat />
          </BigCard>
        </Grid>
        <Grid item lg={6} md={12} xl={6} xs={12}>
          <BigCard title={`${i18n.t('overview_of_iba_status_in_spain')} ${i18n.t(country.name)}`}>
            <OverviewOfIBAs />
          </BigCard>
        </Grid>
        <Grid item xs={12}>
          <BigCard title={i18n.t('pressure_of_particular_iba_for_a_particular_threat')}>
            <PressureThreats />
          </BigCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
