/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import LoginForm from './components/LoginForm';
import Map from './components/Map/Map';
import HelpPage from './pages/HelpPage';
import AboutPage from './pages/AboutPage';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Terms from './components/Terms/Terms';
import appConfig from './redux/actions/appConfig';
import user from './redux/actions/user';
import { theme } from './theme';
import IBAPage from './pages/IBAPage';
import ChartsPage from './pages/ChartsPage';
import AnnualAssessmentPage from './pages/AnnualAssessmentPage';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import AppRoute from './components/AppRoute';
import MinimalLayout from './layout/MinimalLayout';
import NewLayout from './layout/NewLayout';
import Logout from './components/Logout';
import Profile from './components/Profile/index';

Sentry.init({
  dsn: 'https://500413faed6f4c72882852d389d9e969@o443747.ingest.sentry.io/5417912',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const Foo = () => <p>Foo</p>;

const Bar = () => <p>Bar</p>;
const App = (props) => {
  const [, setRefresh] = useState(null);

  useEffect(() => {
    setTimeout(function () {
      props.dispatchGetAppConfig();
      props.dispatchGetUser();
      setRefresh(1);
    }, 1000);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_PATH}>
        <Suspense fallback="loading">
          <AppRoute path="/foo" layout={NewLayout} component={Foo} />
          <AppRoute path="/bar" layout={MinimalLayout} component={Bar} />
          <AppRoute path="/charts" layout={NewLayout} component={ChartsPage} />
          <AppRoute path="/login" layout={NewLayout} component={LoginForm} />
          <AppRoute path="/logout" layout={NewLayout} component={Logout} />
          <AppRoute exact path="/" layout={MinimalLayout} component={LandingPage} />
          <AppRoute path="/explore" layout={NewLayout} component={Map} />
          <AppRoute path="/assessment" layout={NewLayout} component={AnnualAssessmentPage} />
          <AppRoute path="/iba" layout={NewLayout} component={IBAPage} />
          <AppRoute path="/help" layout={NewLayout} component={HelpPage} />
          <AppRoute path="/about" layout={NewLayout} component={AboutPage} />
          <AppRoute path="/terms" layout={NewLayout} component={Terms} />
          <AppRoute path="/profile" layout={NewLayout} component={Profile} />
          <AppRoute path="/privacypolicy" layout={NewLayout} component={PrivacyPolicy} />
        </Suspense>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetAppConfig: () => {
      dispatch(appConfig.fetchAppConfigAsync());
    },
    dispatchGetUser: () => {
      dispatch(user.fetchUserAsync());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
