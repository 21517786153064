import { useEffect, useState } from 'react';
import { store } from '../redux/store';
import { createIsAuthenticatedSelector } from '../redux/selectors/appSelector';

export function useIsAuthenticated() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const state = store.getState();
  const auth = createIsAuthenticatedSelector()(state);

  useEffect(() => {
    setIsAuthenticated(auth);
  }, [auth]);

  return isAuthenticated;
}
