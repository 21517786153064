import loading from '../actions/loading';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import user from '../actions/user';
import { openIdService } from './../../services/openId';
import { naturaAlertApi } from './../../api/index';
import careTakers from '../actions/careTakers';

function* fetchUserAsync() {
  yield put(loading.start({ key: 'fetchUserAsync', opacity: 1 }));
  const data = yield call(openIdService.getUser);
  yield put(user.setUser(data));
  try {
    if (data.profile.sub) {
      const res = yield call(naturaAlertApi.getIbaByCaterTakerId, data.profile.sub);
      if (res.success) {
        yield put(careTakers.setIbaList(res.ibas));
      }
    }
  } catch (e) {
    console.error(e);
  }
  yield put(loading.stop({ key: 'fetchUserAsync' }));
}

function* loginAsync() {
  yield call(openIdService.signIn);
}

export default function* () {
  yield all([
    takeLatest(user.fetchUserAsync.type, fetchUserAsync),
    takeLatest(user.loginAsync.type, loginAsync),
  ]);
}
