import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { naturaAlertApi } from './../../api/index';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
function IbaDropDown({ iba, setIba, isSearch }) {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [search, setSearch] = useState(null);
  const isCaretaker = useSelector((state) => state?.user?.isCaretaker);
  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const sub = useSelector((state) => state?.user?.sub);

  useEffect(() => {
    setLoading(true);
    (async () => {
      var res;
      if (isCaretaker && !isSearch) {
        res = await naturaAlertApi.getIbaByCaterTakerId(sub);
        if (res && res.ibas) {
          setOptions(res.ibas);
        }
      } else {
        res = await naturaAlertApi.getPagedIbas({
          search: search,
          sorting: 'Code',
          maxResultCount: 25,
        });
        if (res && res.items) {
          setOptions(res.items);
        }
      }

      setLoading(false);
    })();
  }, [isAdmin, isCaretaker, isSearch, search, sub]);

  return (
    <div>
      <Autocomplete
        id="iba-list"
        getOptionLabel={(option) => `${option.code ? option.code + '.' : ''} ${option.nationalName}`}
        value={iba}
        onChange={(_e, val) => {
          setIba(val);
        }}
        onInputChange={(_e, val) => {
          setSearch(val);
        }}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={i18n.t('Iba')}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

export default IbaDropDown;
