import React, { useEffect, useState } from 'react';
import { naturaAlertApi } from './../../api/index';
import { TreeMap } from '@nivo/treemap';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const Tooltip = ({ id, data }) => (
  <div
    style={{
      color: '#126a5f',
      fontSize: '13px',
      textAlign: 'center',
      margin: '1px',
    }}
  >
    {data.ibaInternationalName}: {data.count}
  </div>
);

function IBAsInDanger() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const country = useSelector((state) => state.charts.country);
  useEffect(() => {
    (async () => {
      const payload = {};
      setIsLoading(true);
      payload['tenantName'] = country?.name;
      //fetch data from statistics api
      const response = await naturaAlertApi.getIBAsWithThreatCount(payload);
      //add iba code to results, sort and limit to 15
      const result = response
        .map((d) => ({ ...d, id: d.ibaCode }))
        .sort((a, b) => a.count - b.count)
        .reverse()
        .slice(0, 15);
      setData(result);
      setIsLoading(false);
    })();
  }, [country.name]);

  return (
    <div>
      <div className="content">
        {isLoading && data && data.length ? (
          <CircularProgress />
        ) : (
          <TreeMap
            width={500}
            height={300}
            root={{
              id: 'root',
              name: 'Chart1',
              children: data,
            }}
            colorBy="ibaCode"
            identity="ibaCode"
            value="count"
            label="ibaCode"
            colors={{ scheme: 'greens' }}
            labelSkipRadius={12}
            tooltip={Tooltip}
          />
        )}
      </div>
    </div>
  );
}

export default IBAsInDanger;
