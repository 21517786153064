import React, { useState } from 'react';
import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18next';
import { Button, Collapse } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TIMING, SEVERITY, SCOPE, THREAT_TYPE, THREAT_SUBTYPE } from '../../constants';
import Fab from '@material-ui/core/Fab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { format } from 'date-fns';
import { naturaAlertApi } from './../../api/index';
import map from './../../redux/slices/map';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import IbaDropDown from './../IBA/IbaDropDown';
import snackBarSlice from '../../redux/slices/snackbar';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
  button: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  padding: {
    padding: theme.spacing(2),
  },
}));
function Search() {
  const classes = useStyles();
  var isAdmin = useSelector((state) => state?.user?.isAdmin);
  const [isDownloadBusy, setDownloadIsBusy] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [type, setType] = useState(null);
  const [subType, setSubType] = useState(null);
  const [timing, setTiming] = useState('');
  const [severity, setSeverity] = useState('');
  const [scope, setScope] = useState('');
  const [searchVisible, setSearchVisible] = useState(true);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [iba, setIba] = useState(null);
  const dispatch = useDispatch();

  const searchThreats = () => {
    const payload = getPayload();

    naturaAlertApi
      .getThreatsFiltered(payload)
      .then((response) => {
        dispatch(map.actions.setThreat(response));
        dispatch(
          snackBarSlice.actions.showSnack({
            isOpen: true,
            message: `Found ${response.length} threats`,
            severity: 'info',
          }),
        );
        setShowExport(true);
      })
      .catch((err) => {
        setShowExport(false);
        console.log('searchThreats -> err', err);
      });
  };

  const downloadExcelFile = () => {
    setDownloadIsBusy(true);
    const payload = getPayload();
    const element = document.createElement('a');
    naturaAlertApi
      .downloadExcelExport(payload)
      .then((data) => {
        const file = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'ThreatExport-' + new Date().toString() + '.xlsx';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      })
      .catch(() => setDownloadIsBusy(false))
      .then(() => setDownloadIsBusy(false));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h2>{i18n.t('search')}</h2>
        <div className={classes.spacer}></div>
        <Fab color="primary" onClick={() => setSearchVisible(!searchVisible)}>
          {!searchVisible ? <Visibility /> : <VisibilityOff />}
        </Fab>
      </div>
      <div>
        <Collapse in={searchVisible}>
          <Autocomplete
            options={THREAT_TYPE}
            getOptionLabel={(option) => `${option.value}. ${i18n.t(option.label)}`}
            onChange={(_event, newValue) => {
              setType(newValue);
            }}
            value={type}
            renderInput={(params) => <TextField {...params} label={i18n.t('type')} variant="outlined" />}
          />
          <br />
          <Autocomplete
            options={THREAT_SUBTYPE.filter((subType) => type && subType.threatType === type.value)}
            getOptionLabel={(option) => `${option.value}. ${i18n.t(option.label)}`}
            onChange={(_event, newValue) => {
              setSubType(newValue);
            }}
            value={subType}
            renderInput={(params) => <TextField {...params} label={i18n.t('subType')} variant="outlined" />}
          />
          <br />
          <Autocomplete
            options={SEVERITY}
            getOptionLabel={(option) => `${i18n.t(option.label)}`}
            onChange={(_event, newValue) => {
              setSeverity(newValue);
            }}
            value={severity}
            renderInput={(params) => <TextField {...params} label={i18n.t('severity')} variant="outlined" />}
          />
          <br />
          <Autocomplete
            options={TIMING}
            getOptionLabel={(option) => `${i18n.t(option.label)}`}
            onChange={(_event, newValue) => {
              setTiming(newValue);
            }}
            value={timing}
            renderInput={(params) => <TextField {...params} label={i18n.t('timing')} variant="outlined" />}
          />
          <br />
          <Autocomplete
            options={SCOPE}
            getOptionLabel={(option) => `${i18n.t(option.label)}`}
            onChange={(_event, newValue) => {
              setScope(newValue);
            }}
            value={scope}
            renderInput={(params) => <TextField {...params} label={i18n.t('scope')} variant="outlined" />}
          />
          <br />
          <IbaDropDown iba={iba} setIba={setIba} isSearch={true} />
          <br />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                onChange={(_event, newValue) => {
                  setDateFrom(newValue);
                }}
                value={dateFrom}
                id="date-picker-inline"
                label={i18n.t('DateFrom')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                value={dateTo}
                onChange={(_event, newValue) => {
                  setDateTo(newValue);
                }}
                id="date-picker-inline"
                label={i18n.t('DateTo')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <br />
          <div className={classes.button}>
            <div className={classes.padding}>
              <Button variant="contained" color="secondary" onClick={() => searchThreats()}>
                {i18n.t('Search')}
              </Button>
            </div>
            {showExport && isAdmin && (
              <div className={classes.padding}>
                <Button variant="contained" color="secondary" onClick={() => downloadExcelFile()}>
                  {isDownloadBusy ? <i className="fa fa-spinner fa-spin fa-fw p-2" /> : ''} {i18n.t('Export')}
                </Button>
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );

  function getPayload() {
    const payload = {};

    if (type) {
      payload['TypeCode'] = type.value;
    }

    if (subType) {
      payload['TypeCode'] = subType.value;
    }

    if (timing) {
      payload['Timing'] = timing.value;
    }
    if (severity) {
      payload['Severity'] = severity.value;
    }

    if (scope) {
      payload['Scope'] = scope.value;
    }

    if (dateFrom) {
      payload['DateTimeFrom'] = format(new Date(dateFrom), 'yyyy-MM-dd');
    }

    if (dateTo) {
      payload['DateTimeTo'] = format(new Date(dateTo), 'yyyy-MM-dd');
    }

    if (iba && iba.code) {
      payload['IBACode'] = iba.code;
    }
    return payload;
  }
}

export default Search;
