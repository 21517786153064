import { createAction } from '@reduxjs/toolkit';

const success = createAction('alert/success');

const error = createAction('alert/error');

const clear = createAction('alert/clear');

export default {
  success,
  error,
  clear,
};
