import React, { PureComponent } from 'react';
import i18n from 'i18next';

class Terms extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      html: '',
    };
  }

  fetchContent = (lang) => {
    var htmlPath = require('../../files/terms_en.txt');
    if (lang === 'es') {
      htmlPath = require('../../files/terms_es.txt');
    } else if (lang === 'fr') {
      htmlPath = require('../../files/terms_fr.txt');
    }

    fetch(htmlPath)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        this.setState({
          html: text,
        });
      });
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    this.fetchContent(i18n.language);
    return (
      <div className="TermsWrapper" style={{ margin: '70px 20px 20px 20px' }}>
        <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
      </div>
    );
  }
}

export default Terms;
