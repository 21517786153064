import React from 'react';
import { Link } from 'react-router-dom';
import './HelpPage.scss';
import { Trans } from 'react-i18next';
const HelpPage = () => {
  return (
    <div className="bg">
      <div className="help-page">
        <div className="help-content">
          <div className="help-title">
            <h1><Trans i18nKey="help" /></h1>
          </div>

          <div className="help-text">
            <p>
              <Trans i18nKey="all_across_the_globe__common_bird_species__such_as_those_living_in_agricultural_fields__are_declining__the_abuse_of_fertilisers_and_the_intensive_farming_practices_are_making_their_habitats_and_food_disappear__spain_is_lucky_to_have_the_largest_natura______network_in_europe__but_many_of_these_sites_are_still_endangered__the_natura_alert_app_and_the_birdlife_network_of_committed_volunteers_will_try_to_make_a_difference_by_reporting_threats_to_biodiversity_and_changes_in_specific_habitats__arid_lands__wetlands__grasslands__etc___" />
            </p>
            <p>
              <Trans i18nKey="the_data_generated_will_help_to_improve_birdlife_s_network_of_important_bird_and_biodiversity_areas_and_other_key_environmentally_sensitive_regions__natura_alert_empowers_citizens_with_a_tool_to_inform_local_authorities_about_the_threats_and_changes_" />
            </p>

            <p className="row">
              <Link className="btn" to="/explore">
                <Trans i18nKey="web" />
              </Link>
              <a
                className="btn"
                href="https://play.google.com/store/apps/details?id=at.ac.iiasa.naturaalert"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans i18nKey="mobile" />
              </a>
            </p>
          </div>
        </div>

        <div className="help-footer">
          <div className="text-wrapper">
            <p><Trans i18nKey="updated" /></p>
            <p><Trans i18nKey="month_____year" /></p>
          </div>
          <div className="text-wrapper">
            <p><Trans i18nKey="size" /></p>
            <p><Trans i18nKey="varies_with_device" /></p>
          </div>
          <div className="text-wrapper">
            <p><Trans i18nKey="installs" /></p>
            <p><Trans i18nKey="number" /></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
