import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import FilterListIcon from '@material-ui/icons/FilterList';
import Validation from '../Validation';
import { useDispatch, useSelector } from 'react-redux';
import map from '../../redux/slices/map';
import MyThreats from '../MyThreats/MyThreats';
import { useIsAuthenticated } from '../../hooks/useIsAuthenticated';
import Search from './../SearchForm/Search';
import { useFeature } from './../../hooks/useFeature';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // borderColor: 'teal',
    // borderWidth: '5px',
    // borderStyle: 'solid',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const tabRef = useRef(null);
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.map.activeTab);
  const isOpen = useSelector((state) => state.map.isOpen);
  const isAuthenticated = useIsAuthenticated();
  const isCaretaker = useSelector((state) => state?.user?.isCaretaker);
  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const handleChange = (event, newValue) => {
    dispatch(map.actions.setActiveTab(newValue));
  };

  useEffect(() => {
    setTimeout(() => {
      if (tabRef.current) {
        tabRef.current.updateIndicator();
      }
    }, 1000);
  }, [isOpen]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs action={tabRef} value={activeTab} onChange={handleChange} centered>
          <Tab icon={<SearchIcon />} aria-label="search" {...a11yProps(0)} />
          {useFeature('NaturaAlert.MyReports') && isAuthenticated && (
            <Tab icon={<FilterListIcon />} aria-label="filter" {...a11yProps(1)} />
          )}
          {useFeature('NaturaAlert.Search') && (isCaretaker || isAdmin) && (
            <Tab icon={<CheckIcon />} aria-label="validation" {...a11yProps(2)} />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={activeTab} index={0}>
        <div>
          <Search />
        </div>
      </TabPanel>

      {useFeature('NaturaAlert.MyReports') && isAuthenticated && (
        <TabPanel value={activeTab} index={1}>
          <div>
            <MyThreats />
          </div>
        </TabPanel>
      )}
      {useFeature('NaturaAlert.Validation') && (isCaretaker || isAdmin) && (
        <TabPanel value={activeTab} index={2}>
          <div>
            <Validation />
          </div>
        </TabPanel>
      )}
    </div>
  );
}
