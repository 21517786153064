import { all, fork } from 'redux-saga/effects';
import appConfig from './app';
import threat from './threat';
import user from './user';
import iba from './iba';

// Watchers
// function* watchAndLog() {
//   while (true) {
//     // const action = yield take('*');
//     // const state = yield select();
//     // console.log('action', action);
//     // console.log('state after', state);
//   }
// }

export default function* root() {
  yield all([fork(appConfig), fork(threat), fork(user), fork(iba)]);
}
