import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';
import { emailValidation } from '../../utils/commonUtils';
import './feedback.scss';
import { FEED_BACK_TOPICS } from '../../constants';
import { naturaAlertApi } from '../../api';

const FeebackButton = (props) => {
  return (
    <div className="feedback-btn">
      <Button variant="contained" color="primary" onClick={props.onOpen}>
        {' '}
        {i18n.t('Contact')}
      </Button>
    </div>
  );
};

const FeedbackModal = (props) => {
  const submitForm = (e) => {
    e.preventDefault();
    if (props.msg && props.email && props.selectedTopic) {
      naturaAlertApi
        .postFeedBack({ email: props.email, message: props.msg, topic: props.selectedTopic })
        .then((data) => {
          if (data.success) {
            props.closeModal();
          }
        });
    }
  };

  return (
    <Dialog
      onClose={props.closeModal}
      aria-labelledby="contact us form"
      open={props.openModal}
      style={{ zIndex: '10000' }}
    >
      <DialogTitle id="feedback-title" className="title">
        {i18n.t('ContactUsAndFeedBack')}
        <IconButton aria-label="Close" onClick={props.dismiss}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form noValidate autoComplete="off" onSubmit={submitForm}>
        <DialogContent>
          <Typography>{i18n.t('FeedBackText1')}</Typography>
          <TextField
            required
            error={props.errors.email ? true : false}
            style={{ width: '100%' }}
            id="email-required"
            label={i18n.t('Email')}
            defaultValue=""
            className="email"
            margin="normal"
            onChange={props.onEmailField}
          />
          <FormControl required className="topic-dropdown">
            <InputLabel htmlFor="topic-native-required">{i18n.t('Topic')}</InputLabel>
            <Select
              native
              required
              value={props.selectedTopic}
              onChange={props.onTopicSelectHandler}
              name="Topic"
              error={props.errors.topic ? true : false}
              inputProps={{
                id: 'topic-native-required',
              }}
            >
              <option value=""></option>
              {FEED_BACK_TOPICS.map((item) => (
                <option key={item.id} value={item.value}>
                  {i18n.t(item.value)}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl required className="message-box">
            <TextField
              required
              id="outlined-multiline-flexible"
              label={i18n.t('Message')}
              error={props.errors.message ? true : false}
              multiline
              rows="4"
              cols="4"
              value={props.msg}
              onChange={props.onMessageHandler}
              margin="normal"
              variant="outlined"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            disabled={!props.errors.email ? false : true}
            color="primary"
            fullWidth
          >
            {i18n.t('Submit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
class Feeback extends React.PureComponent {
  state = {
    shouldOpen: false,
    selectedTopic: '',
    email: '',
    message: '',
    errors: {
      email: false,
      message: false,
      topic: false,
    },
  };

  errors = {
    email: false,
    message: false,
    topic: false,
  };

  onCloseHandler = async () => {
    try {
      this.setState({
        shouldOpen: false,
        selectedTopic: '',
        email: '',
        message: '',
      });
    } catch (e) {
      console.error(e.response);
    }
  };

  onDismiss = () => {
    this.errors.email = false;
    this.errors.topic = false;
    this.errors.message = false;
    this.setState({
      shouldOpen: false,
      errors: { ...this.errors },
      selectedTopic: '',
      email: '',
      message: '',
    });
  };

  onOpenHandler = () => {
    this.setState({ shouldOpen: true });
  };

  onTopicSelect = (e) => {
    const { value } = e.target;
    this.errors.topic = !value ? true : false;
    this.setState({
      selectedTopic: value,
      errors: { ...this.errors },
    });
  };

  onEmailChange = (e) => {
    const { value } = e.target;
    this.errors.email = emailValidation(value) ? false : true;
    this.setState({
      email: value,
      errors: { ...this.errors },
    });
  };

  onMessage = (e) => {
    const { value } = e.target;
    this.errors.message = !value ? true : false;
    this.setState({
      message: value,
      errors: { ...this.errors },
    });
  };

  render() {
    return (
      <div>
        <FeebackButton onOpen={this.onOpenHandler} />
        <FeedbackModal
          errors={this.state.errors}
          openModal={this.state.shouldOpen}
          onEmailField={this.onEmailChange}
          closeModal={this.onCloseHandler}
          dismiss={this.onDismiss}
          onTopicSelectHandler={this.onTopicSelect}
          selectedTopic={this.state.selectedTopic}
          onMessageHandler={this.onMessage}
          email={this.state.email}
          msg={this.state.message}
        />
      </div>
    );
  }
}

export default Feeback;
