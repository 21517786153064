import { combineReducers } from 'redux';
import user from './user';
import threats from './threats';
import ibas from './ibas';
import annualAssessment from './annualAssessment';
import appConfig from './appConfig';
import alert from './alerts';
import loading from './loading';
import careTakers from './careTakers';
import mapSlice from '../slices/map';
import snackBarSlice from '../slices/snackbar';
import chartsSlice from './../slices/charts';

var charts = chartsSlice.reducer;
var snackbar = snackBarSlice.reducer;
var map = mapSlice.reducer;
const rootReducer = combineReducers({
  user,
  threats,
  ibas,
  annualAssessment,
  appConfig,
  alert,
  loading,
  careTakers,
  map,
  snackbar,
  charts,
});

export default rootReducer;
