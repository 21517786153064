import React, { useEffect } from 'react';
import i18n from './../../i18n';
import { useSelector, useDispatch } from 'react-redux';
import { useIsAuthenticated } from './../../hooks/useIsAuthenticated';
import threatAction from '../../redux/actions/threat';
import { makeStyles } from '@material-ui/core/styles';
import ThreatList from '../Validation/ThreatList';
const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
  },
}));

export default function MyThreats() {
  const classes = useStyles();
  const userThreats = useSelector((state) => state?.threats?.userThreats);
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(threatAction.fetchUserThreatAsync());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div>
      <div className={classes.header}>
        <h1>{i18n.t('MyThreats')}</h1>
      </div>
      <div className="threat-list">
        <ThreatList threats={userThreats}></ThreatList>
      </div>
    </div>
  );
}
