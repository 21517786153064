import { useEffect, useState } from 'react';

export function useLocation() {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation(position.coords);
    });
  }, []);

  return location;
}
