import { createAction } from '@reduxjs/toolkit';

const fetchUserThreatAsync = createAction('threat/fetchUserThreatAsync');
const fetchThreatAsync = createAction('threat/fetchThreatAsync');
const fetchThreatSearchAsync = createAction('threat/fetchThreatSearchAsync');

const createThreatAsync = createAction('threat/createThreatAsync');
const updateThreatAsync = createAction('threat/updateThreatAsync');
const deleteThreatAsync = createAction('threat/deleteThreatAsync');
const verifyThreatAsync = createAction('threat/verifyThreatAsync');

const setUserThreats = createAction('threat/setUserThreats');
const setThreats = createAction('threat/setThreat');
const setThreatSearch = createAction('threat/setThreatSearch');
const setSelectedThreat = createAction('threat/setSelectedThreat');
const setCreateThreat = createAction('threat/setCreateThreat');
const setUpdateThreat = createAction('threat/setUpdateThreat');
const setDeleteThreat = createAction('threat/setDeleteThreat');
const setIsThreatDialogOpen = createAction('threat/setIsThreatDialogOpen');
const setIsThreatValidationDialogOpen = createAction('threat/setIsThreatValidationDialogOpen');

export default {
  fetchUserThreatAsync,
  fetchThreatAsync,
  setUserThreats: setUserThreats,
  setThreats: setThreats,
  createThreatAsync,
  updateThreatAsync,
  deleteThreatAsync,
  fetchThreatSearchAsync,
  setThreatSearch,
  setSelectedThreat,
  setCreateThreat,
  setUpdateThreat,
  setDeleteThreat,
  setIsThreatDialogOpen,
  setIsThreatValidationDialogOpen,
  verifyThreatAsync,
};
