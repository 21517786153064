import { createReducer } from '@reduxjs/toolkit';
import alert from '../actions/alert';
import swal from '@sweetalert/with-react';

const initialState = { activeAlert: {} };

export default createReducer(initialState, (builder) =>
  builder
    .addCase(alert.success, (state, action) => {
      const { key, message } = action.payload;
      swal({
        text: message,
        icon: 'success',
      });
      return {
        type: 'alert-success',
        message: action.message,
        actives: { ...state.activeAlert, [key]: action },
      };
    })
    .addCase(alert.error, (state, action) => {
      const { key, message } = action.payload;
      swal({
        text: message,
        dangerMode: true,
        icon: 'error',
      });
      return {
        type: 'alert-danger',
        message: message,
        actives: { ...state.activeAlert, [key]: action },
      };
    })
    .addCase(alert.clear, () => ({})),
);
