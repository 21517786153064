import { UserManager } from 'oidc-client';
const NATURA_ALERT_API_URL = process.env.REACT_APP_NATURA_ALERT_API_URL;
var path = NATURA_ALERT_API_URL;
//var metadataPath = path + '/.well-known/openid-configuration';
var url = window.location.origin;
var redirect_uri = url + '/signin-callback.html';
var silent_redirect_uri = url + '/silent-renew.html';
var post_logout_uri = url + '/explore';

///////////////////////////////
// OidcClient config
///////////////////////////////

var settings = {
  authority: path,
  client_id: 'NaturaAlert_Spa',
  //client_secret: '1q2w3e*',
  redirect_uri: redirect_uri,
  post_logout_redirect_uri: post_logout_uri,
  response_type: 'code',
  scope: 'openid email role NaturaAlert',
  popupWindowTarget: '_self',
  silent_redirect_uri: silent_redirect_uri,
  automaticSilentRenew: true,
  validateSubOnSilentRenew: true,
  monitorAnonymousSession: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
};
var mgr = new UserManager(settings);
async function signIn() {
  return await mgr.signinPopup();
}

async function getUser() {
  return await mgr.getUser();
}

async function signOut() {
  return await mgr.signoutPopup({
    post_logout_redirect_uri: post_logout_uri,
    returnUrl: url,
  });
}

async function signOut2() {
  return await mgr.signoutRedirectCallback(url);
}

///////////////////////////////
// functions for UI elements
///////////////////////////////
async function clearState() {
  return await mgr.clearStaleState();
}

async function removeUser() {
  return await mgr.removeUser();
}

async function startSigninMainWindow() {
  var someState = { message: 'some data' };
  return await mgr.signinRedirect({ state: someState, useReplaceToNavigate: true });
}

async function endSigninMainWindow() {
  return await mgr.signinCallback();
}

async function startSigninMainWindowDiffCallbackPage() {
  return await mgr.signinRedirect({
    state: 'some data',
    redirect_uri: url + '/code-identityserver-sample-callback.html',
  });
}

async function popupSignin() {
  return await mgr.signinPopup({ state: 'some data' });
}

async function popupSignout() {
  return await mgr.signoutPopup({ state: 'some data' });
}

async function iframeSignin() {
  return await mgr.signinSilent({ state: 'some data' });
}

async function querySessionStatus() {
  return await mgr.querySessionStatus();
}

async function startSignoutMainWindow() {
  return await mgr.signoutRedirect({ state: 'some data' });
}

async function endSignoutMainWindow() {
  return await mgr.signoutCallback();
}

export const openIdService = {
  signIn,
  getUser,
  signOut,
  signOut2,
  endSignoutMainWindow,
  startSignoutMainWindow,
  querySessionStatus,
  iframeSignin,
  clearState,
  removeUser,
  startSigninMainWindow,
  endSigninMainWindow,
  startSigninMainWindowDiffCallbackPage,
  popupSignin,
  popupSignout,
};
