import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { useSelector } from 'react-redux';
import ThreatItem from './ThreatItem';
const ThreatList = ({ threats, isValidation }) => {
  var selectedThreat = useSelector((state) => state?.map?.selectedThreat);
  const rowRenderer = (indexItem) => {
    var index = indexItem.index;
    var key = indexItem.key;
    var style = indexItem.style;
    return (
      <div
        className={
          selectedThreat && threats[index].id === selectedThreat?.id
            ? 'flex flex-row bg-mainLight'
            : 'flex flex-row'
        }
        key={key}
        style={style}
      >
        <ThreatItem threat={threats[index]} isValidation={isValidation}></ThreatItem>
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          rowCount={threats.length}
          width={width}
          height={height}
          rowHeight={100}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};

export default ThreatList;
