import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import i18n from 'i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import annualAssessment from '../../../redux/actions/annualAssessment';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  field: {
    margin: '10px',
  },
}));

function ScreenFive() {
  const protectionOrganizationAnswer = useSelector(
    (state) => state.annualAssessment.form?.protectionOrganizationAnswer,
  );
  const relationshipBetweenSeo = useSelector((state) => state.annualAssessment.form?.relationshipBetweenSeo);
  const activitiesDoneByOrg = useSelector((state) => state.annualAssessment.form?.activitiesDoneByOrg);
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <FormControl className={classes.form}>
      <TextField
        className={classes.field}
        id="1"
        value={protectionOrganizationAnswer}
        onChange={(e) => dispatch(annualAssessment.setProtectionOrganizationAnswer(e.target.value))}
        multiline
        label={<label>{i18n.t('Screen6Q1')}</label>}
        type="text"
        rows="4"
        variant="outlined"
      ></TextField>
      <TextField
        className={classes.field}
        id="2"
        value={relationshipBetweenSeo}
        onChange={(e) => dispatch(annualAssessment.setRelationshipBetweenSeo(e.target.value))}
        multiline
        label={<label>{i18n.t('Screen6Q2')}</label>}
        type="text"
        rows="4"
        variant="outlined"
      ></TextField>
      <TextField
        className={classes.field}
        id="3"
        value={activitiesDoneByOrg}
        onChange={(e) => dispatch(annualAssessment.setActivitiesDoneByOrg(e.target.value))}
        multiline
        label={<label>{i18n.t('Screen6Q3')}</label>}
        type="text"
        rows="4"
        variant="outlined"
      ></TextField>
    </FormControl>
  );
}

export default ScreenFive;
