import React from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { naturaAlertApi } from './../../api/index';
import { useSelector, useDispatch } from 'react-redux';
import swal from '@sweetalert/with-react';
import careTakers from '../../redux/actions/careTakers';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: theme.spacing(2),
  },
  add: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.dark,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
export default function UserList() {
  const classes = useStyles();
  const selectedIba = useSelector((state) => state?.careTakers?.selectedIba);
  const dispatch = useDispatch();
  return (
    <div>
      <div className={classes.root}>
        <MaterialTable
          columns={[{ title: 'Email', field: 'email' }]}
          options={{
            pageSize: 5,
            selection: true,
            actionsColumnIndex: -1,
            pageSizeOptions: [5, 10, 15, 20],
          }}
          data={(data) =>
            new Promise((resolve, reject) => {
              naturaAlertApi
                .getUsers({
                  filter: data.search,
                  skipCount: data.page * data.pageSize,
                })
                .then((res) => {
                  resolve({
                    data: res.items,
                    page: data.page,
                    totalCount: res.totalCount,
                  });
                });
            })
          }
          title=""
          actions={[
            {
              tooltip: 'Add Selected Users',
              icon: 'add',
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              onClick: (_evt, data) => {
                if (selectedIba) {
                  var payload = {
                    ibaId: selectedIba.id,
                    userIds: [],
                  };
                  data.forEach((user) => {
                    payload.userIds.push(user.id);
                  });
                  naturaAlertApi.addCareTakers(payload).then((_res) => {
                    dispatch(careTakers.setUserList(data));
                    swal({
                      text: 'Users added',
                      icon: 'success',
                    });
                  });
                }
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
